import { TFunction } from "i18next";

export function reactTableTranslationProps(t: TFunction) {
    return {
        previousText: t("previous-page"),
        nextText: t("next-page"),
        loadingText: t("loading"),
        noDataText: t("no-data"),
        pageText: t("page"),
        ofText: t("of"),
        rowsText: t("rows"),
    };
}