import * as React from "react";

interface IProps {
    children?: any;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
}

export default function InlineButton(props: IProps) {
    const { children, className, onClick, disabled, loading } = props;

    return (
        <button
            className={"button is-small-inline is-outlined is-dark " + (loading ? "is-loading " : "") + className}
            type="button"
            onClick={onClick}
            disabled={disabled || loading}
        >
            {children}
        </button>
    );
}