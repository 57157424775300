import * as React from "react";
import browserHistory from "../../utils/browserHistory";

interface IProps {
    to: string;
    className?: string;
    children?: any;
}

export default function NavBarItemLink(props: IProps) {
    const { to, children, className } = props;

    return (
        <a className={className || "navbar-item"} onClick={() => browserHistory.push(to)}>
            {children}
        </a>
    );
}
