import axios, { CancelToken, AxiosResponse, AxiosPromise } from "axios";
import { ILoginResult } from "../../shared/definitions/apiResults/ILoginResult";
import { ILoggedInUserDataResult } from "../../shared/definitions/apiResults/ILoggedInUserDataResult";
import { IInvitation } from "../../shared/definitions/models/IInvitation";
import { ClientRole } from "../../shared/definitions/clientRole";
import { IInvitationDataForSignUpResult } from "../../shared/definitions/apiResults/IInvitationDataForSignUpResult";
import { ISignUpResult } from "../../shared/definitions/apiResults/ISignUpResult";
import { IGetAllPaginatedResult } from "../../shared/definitions/apiResults/IGetAllPaginatedResult";
import { SortingRule, Filter } from "react-table";
import { IUser } from "../../shared/definitions/models/IUser";
import { DeleteInvitationResult } from "../../shared/definitions/apiResults/DeleteInvitationResult";
import { IServerErrorResult } from "../../shared/definitions/apiResults/IServerErrorResult";
import { ConfirmEmailResult } from "../../shared/definitions/apiResults/ConfirmEmailResult";
import { ResendAccountCreationBackupEmailConfirmationResult } from "../../shared/definitions/apiResults/ResendAccountCreationBackupEmailConfirmationResult";
import { stores } from "../stores";
import { ILicenseType } from "../../shared/definitions/models/ILicenseType";
import { DeleteLicenseTypeResult } from "../../shared/definitions/apiResults/DeleteLicenseTypeResult";
import { IClientData } from "../../shared/definitions/models/IClientData";
import i18next from "i18next";
import { LogEntryCountsByIP } from "../../shared/definitions/apiResults/LogEntryCountsByIP";

axios.defaults.headers.common["x-csrf-token"] = document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content");

export function createCancelTokenSource() {
    return axios.CancelToken.source();
}

/* ----------------- */
/* -- Auth Routes -- */
/* ----------------- */

export async function login(username: string, password: string) {
    const result = await processDataAndError<ILoginResult>(
        axios.post("/api/login", {
            username,
            password
        })
    );
    restoreDatesObject(result.user);
    return result;
}

export function logout() {
    return processAndReturnErrorPromise(
        axios.get("/api/logout")
    );
}

export async function getLoggedInUserData() {
    const result = await processDataAndError<ILoggedInUserDataResult>(
        axios.get("/api/getLoggedInUserData")
    );
    restoreDatesObject(result.user);
    return result;
}

/* -------------------- */
/* -- Sign-up Routes -- */
/* -------------------- */

export function getInvitationDataForSignUp(invitationCode: string) {
    return processDataAndError<IInvitationDataForSignUpResult>(
        axios.get(`/api/signup/getInvitationData/${invitationCode}`)
    );
}

export function signUp(invitationCode: string, username: string, password: string, fullName: string, address: string, phoneNumber: string, backupEmail: string, language: string) {
    return processDataAndError<ISignUpResult>(
        axios.post(`/api/signup/withInvitationCode/${invitationCode}`, {
            username,
            password,
            fullName,
            address,
            phoneNumber,
            backupEmail,
            language
        })
    );
}

/* ------------------- */
/* -- Public routes -- */
/* ------------------- */

export function confirmEmail(code: string, isBackupEmail: boolean) {
    return processDataAndError<ConfirmEmailResult>(
        axios.get(`/api/confirmNew${isBackupEmail ? "Backup" : ""}Email/${code}`)
    );
}

export function resendAccountCreationBackupEmailConfirmation(username: string, password: string) {
    return processDataAndError<ResendAccountCreationBackupEmailConfirmationResult>(
        axios.post("/api/resendAccountCreationBackupEmailConfirmation", {
            username,
            password
        })
    );
}

export function requestUsernames(email: string, language: string) {
    return processAndReturnErrorPromise(
        axios.post("/api/requestUsernames", {
            email,
            language
        })
    );
}

export function requestPasswordReset(username: string, email: string) {
    return processAndReturnErrorPromise(
        axios.post("/api/requestPasswordReset", {
            username,
            email
        })
    );
}

export function resetPassword(username: string, forgottenPasswordRequestCode: string, newPassword: string) {
    return processAndReturnErrorPromise(
        axios.post("/api/resetPassword", {
            username,
            forgottenPasswordRequestCode,
            newPassword
        })
    );
}

/* ----------------- */
/* -- User Routes -- */
/* ----------------- */

export function updateOwnUserData(values: any) {
    return processDataAndError<IUser>(
        axios.post("/api/user/updateData", values)
    );
}

export function changeOwnPassword(password: string) {
    return processAndReturnErrorPromise(
        axios.post(`/api/user/changePassword`, { password })
    );
}

export function changeOwnLanguage(language: string) {
    return processAndReturnErrorPromise(
        axios.post(`/api/user/changeLanguage`, { language })
    );
}

export function setOwnNewEmail(newEmail: string) {
    return processDataAndError<IUser>(
        axios.post("/api/user/setNewEmail", { newEmail })
    );
}

export function setOwnNewBackupEmail(newBackupEmail: string) {
    return processDataAndError<IUser>(
        axios.post("/api/user/setNewBackupEmail", { newBackupEmail })
    );
}

export function resendOwnNewEmailConfirmation() {
    return processAndReturnErrorPromise(
        axios.post("/api/user/resendNewEmailConfirmation")
    );
}

export function resendOwnNewBackupEmailConfirmation() {
    return processAndReturnErrorPromise(
        axios.post("/api/user/resendNewBackupEmailConfirmation")
    );
}

export function updateOwnUserContentCreatorData(values: any) {
    return processDataAndError<IUser>(
        axios.post("/api/user/updateContentCreatorData", values)
    );
}

export function generateOwnRegistrationCode() {
    return processDataAndError<IUser>(
        axios.post("/api/user/generateRegistrationCode")
    );
}

export function releaseOwnDevice() {
    return processDataAndError<IUser>(
        axios.post("/api/user/releaseDevice")
    );
}

/* ------------------ */
/* -- Admin Routes -- */
/* ------------------ */

export function createInvitation(email: string, clientRole: ClientRole, language: string) {
    return processDataAndError<IInvitation>(
        axios.post("/api/invitations", {
            email,
            clientRole,
            language
        })
    );
}

export async function getInvitations(limit: number, offset: number, sortingRules: SortingRule[], filters: Filter[], cancelToken: CancelToken) {
    return getPaginatedList<IInvitation>("/api/invitations", limit, offset, sortingRules, filters, cancelToken);
}

export function getInvitation(id: number) {
    return processDataAndError<IInvitation>(
        axios.get(`/api/invitations/${id}`)
    );
}

export function deleteInvitation(id: number) {
    return processDataAndError<DeleteInvitationResult>(
        axios.delete(`/api/invitations/${id}`)
    );
}

export function resendInvitationEmail(id: number) {
    return processAndReturnErrorPromise(
        axios.post(`/api/invitations/${id}/resendEmail`)
    );
}

export async function getClients(limit: number, offset: number, sortingRules: SortingRule[], filters: Filter[], cancelToken: CancelToken) {
    return getPaginatedList<IUser>("/api/clients", limit, offset, sortingRules, filters, cancelToken);
}

export function getClient(id: number) {
    return processDataAndError<IUser>(
        axios.get(`/api/clients/${id}`)
    );
}

export function deleteClient(id: number) {
    return processAndReturnErrorPromise(
        axios.delete(`/api/clients/${id}`)
    );
}

export function updateClientData(id: number, values: any) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/updateData`, values)
    );
}

export function setClientNewEmail(id: number, newEmail: string) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/setNewEmail`, { newEmail })
    );
}

export function setClientNewBackupEmail(id: number, newBackupEmail: string) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/setNewBackupEmail`, { newBackupEmail })
    );
}

export function resendClientNewEmailConfirmation(id: number) {
    return processAndReturnErrorPromise(
        axios.post(`/api/clients/${id}/resendNewEmailConfirmation`)
    );
}

export function resendClientNewBackupEmailConfirmation(id: number) {
    return processAndReturnErrorPromise(
        axios.post(`/api/clients/${id}/resendNewBackupEmailConfirmation`)
    );
}

export function setClientActiveState(id: number, active: boolean) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/setActiveState`, { active })
    );
}

export function updateClientContentCreatorData(id: number, values: any) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/updateContentCreatorData`, values)
    );
}

export function generateClientRegistrationCode(id: number) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/generateRegistrationCode`)
    );
}

export function setClientContentType(id: number, contentType: number) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/setContentType`, { contentType })
    );
}

export function setClientLicense(id: number, licenseTypeId: number, startDate: Date, endDate: Date) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/setLicense`, {
            licenseTypeId,
            startDateTimestamp: startDate.getTime(),
            endDateTimestamp: endDate.getTime()
        })
    );
}

export function prolongClientLicense(id: number, licenseTypeId: number, endDate: Date) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/setLicense`, {
            licenseTypeId,
            endDateTimestamp: endDate.getTime()
        })
    );
}

export function revokeClientLicense(id: number) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/revokeLicense`)
    );
}

export function releaseClientDevice(id: number) {
    return processDataAndError<IUser>(
        axios.post(`/api/clients/${id}/releaseDevice`)
    );
}

export function createLicenseType(name: string, durationInMonths: number, clientRole: ClientRole) {
    return processDataAndError<ILicenseType>(
        axios.post("/api/licenseTypes", {
            name,
            durationInMonths,
            clientRole
        })
    );
}

export async function getLicenseTypes(limit: number, offset: number, sortingRules: SortingRule[], filters: Filter[], cancelToken: CancelToken) {
    return getPaginatedList<ILicenseType>("/api/licenseTypes", limit, offset, sortingRules, filters, cancelToken);
}

export async function getSelectableLicenseTypes(clientRole: ClientRole) {
    return processDataAndError<ILicenseType[]>(
        axios.get(`/api/licenseTypes/selectable/${clientRole}`)
    );
}

export function getLicenseType(id: number) {
    return processDataAndError<ILicenseType>(
        axios.get(`/api/licenseTypes/${id}`)
    );
}

export function deleteLicenseType(id: number) {
    return processDataAndError<DeleteLicenseTypeResult>(
        axios.delete(`/api/licenseTypes/${id}`)
    );
}

export function setLicenseTypeRetired(id: number, retired: boolean) {
    return processDataAndError<ILicenseType>(
        axios.post(`/api/licenseTypes/${id}/setRetired`, {
            retired
        })
    );
}

export async function getLogEntries(limit: number, offset: number, sortingRules: SortingRule[], filters: Filter[], cancelToken: CancelToken) {
    return getPaginatedList<IUser>("/api/logEntries", limit, offset, sortingRules, filters, cancelToken);
}

export function getLogEntry(id: number) {
    return processDataAndError<IUser>(
        axios.get(`/api/logEntries/${id}`)
    );
}

export async function getLogEntryCountsByIP() {
    return processDataAndError<LogEntryCountsByIP>(
        axios.get(`/api/logEntries/countsByIP`)
    );
}

/* ---------------------- */
/* -- Helper functions -- */
/* ---------------------- */

async function getPaginatedList<TResult>(url: string, limit: number, offset: number, sortingRules: SortingRule[], filters: Filter[], cancelToken: CancelToken) {
    try {
        const params = {
            limit,
            offset,
            sort: encodeURI(JSON.stringify(sortingRules)),
            filter: encodeURI(JSON.stringify(filters))
        };
        const res = await axios.get(url, { params, cancelToken });
        const paginatedResult = res.data as IGetAllPaginatedResult<TResult>;
        paginatedResult.rows = paginatedResult.rows.map(restoreDatesObject);
        return paginatedResult;
    } catch (err) {
        processError(err);
        throw err;
    }
}

function restoreDatesObject(obj: any) {
    if (!obj)
        return obj;

    if (typeof (obj) === "object") {
        obj.createdAt = restoreDate(obj.createdAt);
        obj.updatedAt = restoreDate(obj.updatedAt);
    }

    if (obj && obj.clientData && obj.clientData.licenseData) {
        const { licenseData, licenseData: { licenseDeviceData } } = (obj.clientData as IClientData);
        licenseData.startDate = restoreDate(licenseData.startDate);
        licenseData.endDate = restoreDate(licenseData.endDate);
        if (licenseDeviceData) {
            licenseDeviceData.nextReleasePossible = restoreDate(licenseDeviceData.nextReleasePossible);
            licenseDeviceData.registrationCodeExpirationDate = restoreDate(licenseDeviceData.registrationCodeExpirationDate);
        }
    }

    return obj;
}

function restoreDate(date: any) {
    if (!date)
        return undefined;

    return new Date(date);
}

function processDataAndError<T>(axiosPromise: AxiosPromise) {
    return axiosPromise
        .catch(err => {
            processError(err);
            throw err;
        })
        .then(res => restoreDatesObject(res.data) as T);
}

function processAndReturnErrorPromise(axiosPromise: AxiosPromise): AxiosPromise {
    return axiosPromise
        .catch(err => {
            processError(err);
            throw err;
        });
}

function processError(err: any) {
    if (err.response) {
        const { status } = err.response as AxiosResponse;
        if (status === 401) {
            stores.appStateStore.setUserHasBeenLoggedOut();
        }
    }
}

export function errorToString(err: any): string {
    if (err.response) {
        const { status, data } = err.response as AxiosResponse;
        switch (status) {
            case 401:
                return i18next.t("error-401-logged-out");

            case 403:
                return i18next.t("error-403-insufficent-rights");

            case 404:
                return i18next.t("error-404-not-found");

            case 500:
                if (data.error) {
                    const error = (data as IServerErrorResult).error;
                    if (error.translationKey) {
                        return i18next.t(error.translationKey);
                    }

                    err = error;
                }
                break;
        }
    }

    return err.name + ": " + err.message;
}