import { languageData, getValidLanguage } from "../config/languageData";

export function dateTimeToString(date: Date, language: string) {
    try {
        if (!date)
            return "";

        let data = languageData[language];
        if (!data) {
            data = languageData[getValidLanguage(language)];
        }

        return data.dateTimeFormat.format(date.getTime());
    } catch (err) {
        console.error(err);
        return "Error: " + date.toString();
    }
}