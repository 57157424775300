import * as Yup from "yup";
import { ClientRole } from "../clientRole";

export const createLicenseTypeSchema = Yup.object().shape({
    name: Yup.string()
        .required("required"),
    durationInMonths: Yup.number()
        .integer("must-be-integer")
        .min(1, "must-be-at-least-1-month")
        .required("required"),
    clientRole: Yup.string()
        .oneOf([
            ClientRole.ContentCreator,
            ClientRole.DeviceUser
        ], "invalid-option")
});
