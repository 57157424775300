import * as React from "react";
import { match } from "react-router";
import { getClient, updateClientData, setClientNewEmail, setClientNewBackupEmail, resendClientNewEmailConfirmation, resendClientNewBackupEmailConfirmation, generateClientRegistrationCode, getSelectableLicenseTypes, errorToString, releaseClientDevice, updateClientContentCreatorData } from "../../../communication/api";
import { IAsyncLoadedObject, load } from "../../../utils/asyncLoader";
import { Loading } from "../../shared/Loading";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import { IUser } from "../../../../shared/definitions/models/IUser";
import UserDetails from "../../shared/UserDetails/UserDetails";
import bind from "bind-decorator";
import DeleteClientModal from "./DeleteClientModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ILicenseType } from "../../../../shared/definitions/models/ILicenseType";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageTitle from "../../shared/PageTitle";
import { WithTranslation, withTranslation } from "react-i18next";

interface IMatchParameters {
    id: string;
}

interface IProps extends WithTranslation {
    match: match<IMatchParameters>;
}

interface IState {
    id: number;
    loadedClient: IAsyncLoadedObject<IUser>;
    showDeletionModal: boolean;
    licenseTypes: ILicenseType[];
    licenseTypesFetchError: string;
}

class ShowClient extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            id: Number.parseInt(props.match.params.id, 10),
            loadedClient: {
                error: "",
                loading: false,
                value: null
            },
            showDeletionModal: false,
            licenseTypes: [],
            licenseTypesFetchError: null
        };
    }

    public async componentDidMount() {
        await this.load();
    }

    @bind
    private async load() {
        this.setState({
            showDeletionModal: false,
            licenseTypesFetchError: null
        });

        const result = await load(this, "loadedClient", () => getClient(this.state.id));

        if (result.value) {
            try {
                const licenseTypes = await getSelectableLicenseTypes(result.value.clientData.clientRole);
                this.setState({
                    licenseTypes
                });
            } catch (err) {
                this.setState({
                    licenseTypes: [],
                    licenseTypesFetchError: errorToString(err)
                });
            }
        }
    }

    @bind
    private setClient(client: IUser) {
        this.setState({
            loadedClient: {
                error: "",
                loading: false,
                value: client
            }
        });
    }

    @bind
    private async setFullName(value: string) {
        this.setClient(await updateClientData(this.state.id, {
            fullName: value
        }));
    }

    @bind
    private async setAddress(value: string) {
        this.setClient(await updateClientData(this.state.id, {
            address: value
        }));
    }

    @bind
    private async setPhoneNumber(value: string) {
        this.setClient(await updateClientData(this.state.id, {
            phoneNumber: value
        }));
    }

    @bind
    private async setNewEmail(value: string) {
        this.setClient(await setClientNewEmail(this.state.id, value));
    }

    @bind
    private async setNewBackupEmail(value: string) {
        this.setClient(await setClientNewBackupEmail(this.state.id, value));
    }

    @bind
    private async resendNewEmailConfirmation() {
        await resendClientNewEmailConfirmation(this.state.id);
    }

    @bind
    private async resendNewBackupEmailConfirmation() {
        await resendClientNewBackupEmailConfirmation(this.state.id);
    }

    @bind
    private async setServerUrl(value: string) {
        this.setClient(await updateClientContentCreatorData(this.state.id, {
            serverUrl: value
        }));
    }

    @bind
    private async setServerUsername(value: string) {
        this.setClient(await updateClientContentCreatorData(this.state.id, {
            serverUsername: value
        }));
    }

    @bind
    private async setServerPassword(value: string) {
        this.setClient(await updateClientContentCreatorData(this.state.id, {
            serverPassword: value
        }));
    }

    @bind
    private async generateRegistrationCode() {
        this.setClient(await generateClientRegistrationCode(this.state.id));
    }

    @bind
    private async releaseDevice() {
        this.setClient(await releaseClientDevice(this.state.id));
    }

    @bind
    private openDeleteModal() {
        this.setState({ showDeletionModal: true });
    }

    @bind
    private closeDeleteModal() {
        this.setState({ showDeletionModal: false });
    }

    public render() {
        const { t } = this.props;
        const { id, loadedClient: { loading, error, value: client }, showDeletionModal, licenseTypes, licenseTypesFetchError } = this.state;

        return (
            <div>
                <Breadcrumbs routeKeys={["admin", "adminClientOverview", id]} />
                {loading && <Loading />}
                <ErrorDisplay error={error} />
                <ErrorDisplay error={licenseTypesFetchError} />
                {client && <PageTitle>{client.fullName} ({client.username})</PageTitle>}
                <UserDetails
                    user={client}
                    adminMode={true}
                    setFullName={this.setFullName}
                    setAddress={this.setAddress}
                    setPhoneNumber={this.setPhoneNumber}
                    setNewEmail={this.setNewEmail}
                    setNewBackupEmail={this.setNewBackupEmail}
                    resendNewEmailConfirmation={this.resendNewEmailConfirmation}
                    resendNewBackupEmailConfirmation={this.resendNewBackupEmailConfirmation}
                    setServerUrl={this.setServerUrl}
                    setServerUsername={this.setServerUsername}
                    setServerPassword={this.setServerPassword}
                    generateRegistrationCode={this.generateRegistrationCode}
                    releaseDevice={this.releaseDevice}
                    adminUserUpdated={this.setClient}
                    adminReloadUser={this.load}
                    adminLicenseTypes={licenseTypes}
                />
                {client && (
                    <div className="box with-top-margin">
                        <PageTitle subtitle={1}>{t("destructive-actions")}</PageTitle>
                        <button className="button is-danger is-outlined is-small-inline" onClick={this.openDeleteModal}><FontAwesomeIcon icon={faTimesCircle} pull="left" /> {t("delete-client-permanently")}</button>
                        {showDeletionModal && <DeleteClientModal client={client} reload={this.load} close={this.closeDeleteModal} />}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ShowClient);