import * as React from "react";
import { DetailDisplay } from "../DetailDisplay";
import { IUser } from "../../../../shared/definitions/models/IUser";
import { dateTimeToString } from "../../../../shared/utils/dateTimeToString";
import { observer } from "mobx-react";
import { UserUpdated } from "./ChangeUserActiveState";
import { ExecuteAsyncAction } from "./UserDetailsMailField";
import bind from "bind-decorator";
import { errorToString, setClientContentType } from "../../../communication/api";
import { ErrorDisplay } from "../ErrorDisplay";
import UserDetailsSetLicense from "./UserDetailsSetLicense";
import { ILicenseType } from "../../../../shared/definitions/models/ILicenseType";
import RevokeLicenseModal from "./RevokeLicenseModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { constants } from "../../../../shared/config/constants";
import InlineButton from "./InlineButton";
import ClientContentTypeEditor from "./ClientContentTypeEditor";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    user: IUser;
    releaseDevice: ExecuteAsyncAction;
    generateRegistrationCode: ExecuteAsyncAction;
    adminMode: boolean;
    adminUserUpdated?: UserUpdated;
    adminReloadUser?: () => void;
    adminLicenseTypes?: ILicenseType[];
}

interface IState {
    registrationCodeGenerating: boolean;
    registrationCodeGeneratingError: string;
    releasingDevice: boolean;
    releasingDeviceError: string;
    showRevokeModal: boolean;
}

@observer
class UserDetailsLicenseDisplay extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            registrationCodeGenerating: false,
            registrationCodeGeneratingError: null,
            releasingDevice: false,
            releasingDeviceError: null,
            showRevokeModal: false
        };
    }

    @bind
    private async generateRegistrationCode() {
        this.setState({
            registrationCodeGenerating: true,
            registrationCodeGeneratingError: null
        });

        try {
            await this.props.generateRegistrationCode();
        } catch (err) {
            this.setState({ registrationCodeGeneratingError: errorToString(err) });
        }

        this.setState({ registrationCodeGenerating: false });
    }

    @bind
    private async releaseDevice() {
        this.setState({
            releasingDevice: true,
            releasingDeviceError: null
        });

        try {
            await this.props.releaseDevice();
        } catch (err) {
            this.setState({ releasingDeviceError: errorToString(err) });
        }

        this.setState({ releasingDevice: false });
    }

    @bind
    private openRevokeModal() {
        this.setState({ showRevokeModal: true });
    }

    @bind
    private closeRevokeModal() {
        this.setState({ showRevokeModal: false });
    }

    @bind
    private async setContentType(value: number) {
        const updatedClient = await setClientContentType(this.props.user.id, value);
        this.props.adminUserUpdated(updatedClient);
    }

    public render() {
        const {
            t, i18n, adminMode, adminUserUpdated, adminReloadUser, adminLicenseTypes, user,
            user: { id, clientData: { licenseData } }
        } = this.props;

        const { registrationCodeGenerating, registrationCodeGeneratingError, releasingDevice, releasingDeviceError, showRevokeModal } = this.state;

        const elements = [];

        let hasNonExpiredLicense = false;

        if (licenseData.licenseType) {
            const { startDate, endDate, licenseType: { name } } = licenseData;

            hasNonExpiredLicense = !licenseData.hasLicenseExpired;

            let status = t("license-active");
            if (licenseData.hasLicenseExpired) {
                status = t("license-expired");
            } else if (!licenseData.hasLicenseStarted) {
                status = t("license-not-started-yet");
            }

            elements.push({ key: "license-type", value: name });
            elements.push({ key: "status", value: status });
            if (startDate) {
                elements.push({ key: "start-date", value: dateTimeToString(startDate, i18n.language) });
            }
            elements.push({ key: "end-date", value: dateTimeToString(endDate, i18n.language) });
        } else {
            elements.push({ key: "license-type", value: t("license-none") });
        }

        const { licenseDeviceData } = licenseData;
        if (licenseDeviceData) {
            const { deviceIdentification, nextReleasePossible, isReleasePossible, contentType, registrationCode, registrationCodeExpirationDate } = licenseDeviceData;

            if (adminMode) {
                elements.unshift({ key: "content-type-id", value: <ClientContentTypeEditor value={contentType} setValue={this.setContentType} /> });
            }

            elements.push({ key: "bound-to-hololens", value: deviceIdentification || t("no") });

            const releaseButtonAndText = (buttonLabel: string) => (
                <span>
                    <span>({t("device-release-info", { count: constants.timeBetweenReleasesDays })})</span>
                    <span className="button-box-inline">
                        <InlineButton onClick={this.releaseDevice} loading={releasingDevice}>{buttonLabel}</InlineButton>
                    </span>
                    <ErrorDisplay error={releasingDeviceError} inline={true} />
                </span>
            );

            elements.push({
                key: "device-release",
                value: (
                    <span>
                        {isReleasePossible
                            ? <span>{t("yes")} {deviceIdentification && releaseButtonAndText(t("release"))}</span>
                            : <span>{dateTimeToString(nextReleasePossible, i18n.language)} {(deviceIdentification && adminMode) && releaseButtonAndText(t("force-release"))}</span>
                        }
                    </span>
                )
            });

            if (licenseData.licenseType) {
                let registrationCodeErrorKey: string;
                if (!licenseData.hasLicenseStarted) {
                    registrationCodeErrorKey = "registration-code-error-license-not-started";
                } else if (licenseData.hasLicenseExpired) {
                    registrationCodeErrorKey = "registration-code-error-license-expired";
                } else if (deviceIdentification) {
                    registrationCodeErrorKey = "registration-code-error-already-has-registered-device";
                }

                if (registrationCodeErrorKey) {
                    elements.push({
                        key: "registration-code", value: (
                            <span>
                                <span className="button-box-inline">
                                    <InlineButton disabled={true}>{t("generate")}</InlineButton>
                                </span>
                                <span>{t(registrationCodeErrorKey)}</span>
                            </span>
                        )
                    });
                } else {
                    elements.push({
                        key: "registration-code", value: (
                            <div>
                                {registrationCode && <span>{registrationCode} (expires {dateTimeToString(registrationCodeExpirationDate, i18n.language)})</span>}
                                <span className={(registrationCode || registrationCodeGeneratingError) ? "button-box-inline" : ""}>
                                    <InlineButton onClick={this.generateRegistrationCode} loading={registrationCodeGenerating}>
                                        {registrationCode ? t("generate-new") : t("generate")}
                                    </InlineButton>
                                </span>
                                <ErrorDisplay error={registrationCodeGeneratingError} inline={true} />
                            </div>
                        )
                    });
                }
            }
        }

        return (
            <div>
                <DetailDisplay elements={elements} />
                {adminMode && (
                    <div>
                        {hasNonExpiredLicense && <button className="button is-danger is-small-inline is-outlined" onClick={this.openRevokeModal}><FontAwesomeIcon icon={faTimesCircle} pull="left" /> {t("revoke-license-action")}</button>}
                        <hr />
                        <UserDetailsSetLicense user={user} userUpdated={adminUserUpdated} reloadUser={adminReloadUser} licenseTypes={adminLicenseTypes} />
                        {showRevokeModal && <RevokeLicenseModal userId={id} userUpdated={adminUserUpdated} reload={adminReloadUser} close={this.closeRevokeModal} />}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(UserDetailsLicenseDisplay);