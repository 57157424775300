import * as React from "react";
import { DetailDisplay } from "../DetailDisplay";
import { IUser } from "../../../../shared/definitions/models/IUser";
import { getClientRoleDisplayText } from "../../../utils/getClientRoleDisplayText";
import { dateTimeToString } from "../../../../shared/utils/dateTimeToString";
import { makeDetailDisplayUserDetailsEditableField, SetFieldValue } from "./UserDetailsEditableField";
import { makeDetailDisplayUserDetailsMailField, ExecuteAsyncAction } from "./UserDetailsMailField";
import { observer } from "mobx-react";
import { userUpdateDetailsSchema } from "../../../../shared/definitions/validationSchemes/user";
import ChangeOwnPassword from "./ChangeOwnPassword";
import ChangeUserActiveState, { UserUpdated } from "./ChangeUserActiveState";
import UserDetailsLicenseDisplay from "./UserDetailsLicenseDisplay";
import { ILicenseType } from "../../../../shared/definitions/models/ILicenseType";
import PageTitle from "../PageTitle";
import UserDetailsContentCreatorDataDisplay from "./UserDetailsContentCreatorDataDisplay";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    user: IUser;
    adminMode: boolean;
    setFullName: SetFieldValue;
    setAddress: SetFieldValue;
    setPhoneNumber: SetFieldValue;
    setNewEmail: SetFieldValue;
    setNewBackupEmail: SetFieldValue;
    resendNewEmailConfirmation: ExecuteAsyncAction;
    resendNewBackupEmailConfirmation: ExecuteAsyncAction;
    setServerUrl: SetFieldValue;
    setServerUsername: SetFieldValue;
    setServerPassword: SetFieldValue;
    generateRegistrationCode: ExecuteAsyncAction;
    releaseDevice: ExecuteAsyncAction;
    adminUserUpdated?: UserUpdated;
    adminReloadUser?: () => void;
    adminLicenseTypes?: ILicenseType[];
}

interface IState {
}

@observer
class UserDetails extends React.Component<IProps, IState> {
    public render() {
        if (!this.props.user)
            return null;

        const {
            t, i18n, adminMode, setFullName, setAddress, setPhoneNumber, setNewEmail, setNewBackupEmail, resendNewEmailConfirmation,
            setServerUrl, setServerUsername, setServerPassword,
            resendNewBackupEmailConfirmation, generateRegistrationCode, releaseDevice,
            adminUserUpdated, adminReloadUser, adminLicenseTypes,
            user, user: { createdAt, username, email, newEmail, fullName, language, clientData }
        } = this.props;

        const elements = [];

        elements.push({ key: "username", value: username });

        if (adminMode) {
            elements.push({ key: "sign-up-date", value: dateTimeToString(createdAt, i18n.language) });
            elements.push({ key: "active", value: <ChangeUserActiveState user={user} userUpdated={adminUserUpdated} /> });
        }

        elements.push(makeDetailDisplayUserDetailsEditableField("full-name", fullName, setFullName, "fullName", false, false, userUpdateDetailsSchema));
        elements.push(makeDetailDisplayUserDetailsMailField("email", email, newEmail, setNewEmail, resendNewEmailConfirmation));

        if (clientData) {
            const { backupEmail, newBackupEmail, address, phoneNumber, clientRole } = clientData;

            elements.push(makeDetailDisplayUserDetailsMailField("it-department-email", backupEmail, newBackupEmail, setNewBackupEmail, resendNewBackupEmailConfirmation));
            elements.push(makeDetailDisplayUserDetailsEditableField("address", address, setAddress, "address", true, false, userUpdateDetailsSchema));
            elements.push(makeDetailDisplayUserDetailsEditableField("phone-number", phoneNumber, setPhoneNumber, "phoneNumber", false, false, userUpdateDetailsSchema));
            elements.push({ key: "role", value: getClientRoleDisplayText(clientRole, t) });
        }

        if (adminMode) {
            elements.push({ key: "language", value: t("language-" + language) });
        }

        return (
            <div>
                <div className="box">
                    <DetailDisplay elements={elements} />
                </div>
                {user.clientData && user.clientData.licenseData && user.clientData.licenseData.contentCreatorData && (
                    <div className="box">
                        <PageTitle subtitle={1}>{t("user-hosted-content-server-title")}</PageTitle>
                        <UserDetailsContentCreatorDataDisplay
                            contentCreatorData={user.clientData.licenseData.contentCreatorData}
                            setServerUrl={setServerUrl}
                            setServerUsername={setServerUsername}
                            setServerPassword={setServerPassword}
                        />
                    </div>
                )}
                {user.clientData && (
                    <div className="box">
                        <PageTitle subtitle={1}>{t("license")}</PageTitle>
                        <UserDetailsLicenseDisplay
                            user={user}
                            generateRegistrationCode={generateRegistrationCode}
                            releaseDevice={releaseDevice}
                            adminMode={adminMode}
                            adminUserUpdated={adminUserUpdated}
                            adminReloadUser={adminReloadUser}
                            adminLicenseTypes={adminLicenseTypes}
                        />
                    </div>
                )}
                {!adminMode && (
                    <div className="box">
                        <PageTitle subtitle={1}>{t("change-password-title")}</PageTitle>
                        <ChangeOwnPassword />
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(UserDetails);