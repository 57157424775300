import * as Yup from "yup";

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 100;

const usernameRequired = Yup.string()
    .required("required");

export const passwordRequired = Yup.string()
    .min(PASSWORD_MIN_LENGTH, "password-too-short")
    .max(PASSWORD_MAX_LENGTH, "password-too-long")
    .required("required");

const passwordConfirmationRequired = Yup.string()
    .oneOf([Yup.ref("password")], "passwords-must-match")
    .required("required");

const fullName = Yup.string();

const address = Yup.string();

const phoneNumber = Yup.string();

export const emailSchema = Yup.string()
    .email("invalid-email");

export const contentTypeIdRequired = Yup.number()
    .min(0)
    .required();

export const signupSchema = Yup.object().shape({
    username: usernameRequired,
    password: passwordRequired,
    passwordConfirmation: passwordConfirmationRequired,
    fullName: fullName.required("required"),
    address: address.required("required"),
    phoneNumber: phoneNumber.required("required"),
    backupEmail: emailSchema.required("required"),
});

export const userUpdateDetailsSchema = Yup.object().shape({
    fullName,
    address,
    phoneNumber
});

export const passwordChangeSchema = Yup.object().shape({
    password: passwordRequired,
    passwordConfirmation: passwordConfirmationRequired
});

export const passwordResetSchema = Yup.object().shape({
    username: usernameRequired,
    password: passwordRequired,
    passwordConfirmation: passwordConfirmationRequired
});

export const setLicenseSchemaClient = Yup.object().shape({
    licenseTypeIndex: Yup.number()
        .min(0, "required")
        .required("required"),
    startDate: Yup.string()
        .required("invalid-date"),
    endDate: Yup.string()
        .required("invalid-date")
});

export const setLicenseSchemaServer = Yup.object().shape({
    licenseTypeId: Yup.number()
        .required("required"),
    startDateTimestamp: Yup.number(),
    endDateTimestamp: Yup.number()
        .required("invalid-date")
});

export const userUpdateContentCreatorDataSchema = Yup.object().shape({
    serverUrl: Yup.string(),
    serverUsername: Yup.string(),
    serverPassword: Yup.string()
});
