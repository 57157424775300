import * as React from "react";
import { errorToString, confirmEmail } from "../../communication/api";
import { Loading } from "./Loading";
import { ErrorDisplay } from "./ErrorDisplay";
import { ConfirmEmailResult } from "../../../shared/definitions/apiResults/ConfirmEmailResult";
import PageTitle from "../shared/PageTitle";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    code: string;
    isBackupEmail: boolean;
}

interface IState {
    error: string;
    errorIsTranslationKey: boolean;
    loading: boolean;
    success: boolean;
    isNewlyRegistered: boolean;
}

class ConfirmEmailShared extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            error: "",
            errorIsTranslationKey: false,
            loading: true,
            success: false,
            isNewlyRegistered: false
        };
    }

    public async componentDidMount() {
        this.setState({
            loading: true
        });

        const { code, isBackupEmail } = this.props;

        try {
            const result: ConfirmEmailResult = await confirmEmail(code, isBackupEmail);
            switch (result) {
                case ConfirmEmailResult.CodeNotFound:
                    this.setState({
                        loading: false,
                        error: "confirmation-code-not-found",
                        errorIsTranslationKey: true
                    });
                    break;

                case ConfirmEmailResult.AlreadyConfirmed:
                    this.setState({
                        loading: false,
                        error: "email-already-confirmed",
                        errorIsTranslationKey: true
                    });
                    break;

                case ConfirmEmailResult.Success:
                    this.setState({
                        loading: false,
                        success: true
                    });
                    break;

                case ConfirmEmailResult.SuccessNewlyRegistered:
                    this.setState({
                        loading: false,
                        success: true,
                        isNewlyRegistered: true
                    });
                    break;

                default:
                    throw new Error("Not implemented: " + result);
            }
        } catch (err) {
            this.setState({
                loading: false,
                error: errorToString(err),
                errorIsTranslationKey: false
            });
        }
    }

    public render() {
        const { t } = this.props;
        const { loading, error, errorIsTranslationKey, success, isNewlyRegistered } = this.state;

        return (
            <div>
                <PageTitle>{t("confirm-email-title")}</PageTitle>
                {loading && <Loading />}
                <ErrorDisplay error={error} translate={errorIsTranslationKey} />
                {success && (
                    <div>
                        <p className="section">{t("confirm-email-success")}</p>
                        {isNewlyRegistered && <p className="section">{t("confirm-email-notified")}</p>}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ConfirmEmailShared);