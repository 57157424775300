import { padNumberString } from "./padNumberString";

export function dateToInputFieldString(date: Date) {
    return dateComponentsToInputFieldString(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
}

export function dateComponentsToInputFieldString(year: number, month: number, day: number, hours: number, minutes: number, seconds: number) {
    return `${padNumberString(year, 4)}-${padNumberString(month, 2)}-${padNumberString(day, 2)}T${padNumberString(hours, 2)}:${padNumberString(minutes, 2)}:${padNumberString(seconds, 2)}`;
}

export function inputFieldStringToDate(dateString: string) {
    if (!dateString)
        return undefined;

    return new Date(dateString);
}

export function daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
}