export const routes = {
    root: "/",
    requestUsernames: "/forgottenUsername",
    requestPasswordReset: "/requestPasswordReset",
    resetPassword: (forgottenPasswordRequestCode: string) => "/resetPassword/" + forgottenPasswordRequestCode,
    admin: "/admin",
    adminInvitationCreate: "/admin/createInvitation",
    adminInvitationView: (id: string | number) => "/admin/invitation/" + id,
    adminInvitationOverview: "/admin/invitations",
    adminClientView: (id: string | number) => "/admin/client/" + id,
    adminClientOverview: "/admin/clients",
    adminLicenseTypeCreate: "/admin/createLicenseType",
    adminLicenseTypeView: (id: string | number) => "/admin/licenseType/" + id,
    adminLicenseTypeOverview: "/admin/licenseTypes",
    adminLogEntriesOverview: "/admin/logEntries",
    adminLogEntriesOverviewWithIP: (ip: string) => "/admin/logEntries/" + ip,
    user: "/user",
    signUpWithInvitationCode: (invitationCode: string) => "/signUp/" + invitationCode,
    confirmNewEmail: (newEmailCode: string) => "/confirmNewEmail/" + newEmailCode,
    confirmNewBackupEmail: (newBackupEmailCode: string) => "/confirmNewBackupEmail/" + newBackupEmailCode
};