import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
}

interface IState {
}

class LogEntriesInfo extends React.Component<IProps, IState> {
    public render() {
        const { t } = this.props;

        return (
            <div>
                <div className="dropdown is-up is-hoverable log-entries-info">
                    <div className="dropdown-trigger">
                        <button className="button" aria-haspopup="true" aria-controls="log-entries-info-dropdown">
                            <span>{t("log-entries-severity-info-button")}</span>
                        </button>
                    </div>
                    <div className="dropdown-menu" id="log-entries-info-dropdown" role="menu">
                        <div className="dropdown-content">
                            <div className="dropdown-item">
                                <div className="section">
                                    <ul>
                                        <li><strong className="severity-2">{t("severity-2")}</strong>: {t("severity-2-info")}</li>
                                        <li><strong className="severity-1">{t("severity-1")}</strong>: {t("severity-1-info")}</li>
                                        <li><strong className="severity-0">{t("severity-0")}</strong>: {t("severity-0-info")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(LogEntriesInfo);