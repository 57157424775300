import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    elements: Array<{
        label?: string;
        key?: string;
        value: string | JSX.Element | JSX.Element[];
    }>;
}

const endsWithAlphaNumeric = /^.*[a-zA-Z0-9]$/;

export function DetailDisplay<T>(props: IProps) {
    const { t } = useTranslation();
    return (
        <table className="vertical">
            <tbody>
                {
                    props.elements.map(element => {
                        let label = element.label;
                        if (element.key) {
                            label = t(element.key);
                            if (endsWithAlphaNumeric.test(label)) {
                                label += ":";
                            }
                        }
                        return (
                            <tr key={label}>
                                <th>{label}</th>
                                <td>{element.value}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
}