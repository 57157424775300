import * as React from "react";
import { Formik, Form, Field } from "formik";
import { errorToString, requestUsernames } from "../../communication/api";
import { ErrorDisplayForm } from "../shared/ErrorDisplayForm";
import { ErrorDisplay } from "../shared/ErrorDisplay";
import { AppStateStore } from "../../stores/appStateStore";
import { inject } from "mobx-react";
import PageTitle from "../shared/PageTitle";
import { BControl, BField, BLabel } from "../shared/BulmaElements";
import Breadcrumbs from "../shared/Breadcrumbs";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
}

interface IState {
    success: boolean;
    formSubmissionError: string;
}

@inject("appStateStore")
class RequestUsernames extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            success: false,
            formSubmissionError: ""
        };
    }

    public componentDidMount() {
        this.props.appStateStore.closeLoginModal();
    }

    public render() {
        const { t, i18n } = this.props;
        const { success, formSubmissionError } = this.state;

        return (
            <div>
                <Breadcrumbs routeKeys={["root", "requestUsernames"]} />
                <PageTitle>{t("forgotten-usernames-title")}</PageTitle>
                <Formik
                    initialValues={{
                        email: ""
                    }}
                    onSubmit={async (values, formikActions) => {
                        this.setState({
                            formSubmissionError: null,
                            success: false
                        });
                        try {
                            await requestUsernames(values.email, i18n.language);
                            this.setState({
                                success: true
                            });
                        } catch (err) {
                            this.setState({ formSubmissionError: errorToString(err) });
                        }
                        formikActions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, isSubmitting, values }) => (
                        <Form>
                            <BField>
                                {t("forgotten-usernames-info")}
                            </BField>
                            <BField>
                                <BLabel>{t("email")}</BLabel>
                                <BControl>
                                    <Field className="input" name="email" type="email" />
                                </BControl>
                                <ErrorDisplayForm error={touched.email && errors.email} />
                            </BField>
                            <BField>
                                <BControl>
                                    <button
                                        className={"button is-primary" + (isSubmitting ? " is-loading" : "")}
                                        type="submit"
                                        disabled={isSubmitting || (!values.email) || (touched.email && !!errors.email)}>
                                        {t("forgotten-usernames-submit")}
                                    </button>
                                </BControl>
                            </BField>
                            <ErrorDisplay error={formSubmissionError} />
                            {success && <BField>{t("forgotten-usernames-success")}</BField>}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withTranslation()(RequestUsernames);