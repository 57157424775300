import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    error: string;
}

export function ErrorDisplayForm({ error }: IProps) {
    const { t } = useTranslation();

    if (!error)
        return null;

    return <p className="help is-danger">{t(error)}</p>;
}