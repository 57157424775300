import * as React from "react";
import { match } from "react-router";
import { ILicenseType } from "../../../../shared/definitions/models/ILicenseType";
import { getLicenseType, errorToString, setLicenseTypeRetired } from "../../../communication/api";
import { Link } from "react-router-dom";
import { dateTimeToString } from "../../../../shared/utils/dateTimeToString";
import { routes } from "../../../../shared/config/routes";
import { IAsyncLoadedObject, load } from "../../../utils/asyncLoader";
import { Loading } from "../../shared/Loading";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import { DetailDisplay } from "../../shared/DetailDisplay";
import bind from "bind-decorator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DeleteLicenseTypeModal from "./DeleteLicenseTypeModal";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageTitle from "../../shared/PageTitle";
import InlineButton from "../../shared/UserDetails/InlineButton";
import { getClientRoleDisplayText } from "../../../utils/getClientRoleDisplayText";
import { WithTranslation, withTranslation } from "react-i18next";

interface IMatchParameters {
    id: string;
}

interface IProps extends WithTranslation {
    match: match<IMatchParameters>;
}

interface IState {
    id: number;
    loadedLicenseType: IAsyncLoadedObject<ILicenseType>;
    showDeletionModal: boolean;
    retireSubmitting: boolean;
    retireError: string;
}

class ShowLicenseType extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            id: Number.parseInt(props.match.params.id, 10),
            loadedLicenseType: {
                error: "",
                loading: false,
                value: null
            },
            showDeletionModal: false,
            retireSubmitting: false,
            retireError: null
        };
    }

    public async componentDidMount() {
        await this.load();
    }

    @bind
    public async load() {
        this.setState({ showDeletionModal: false });
        await load(this, "loadedLicenseType", () => getLicenseType(this.state.id));
    }

    @bind
    public async retire() {
        await this.setRetireStatus(true);
    }

    @bind
    public async unretire() {
        await this.setRetireStatus(false);
    }

    public async setRetireStatus(retired: boolean) {
        this.setState({
            retireSubmitting: true,
            retireError: null
        });

        try {
            const updatedLicenseType = await setLicenseTypeRetired(this.state.id, retired);
            this.setState({
                loadedLicenseType: {
                    value: updatedLicenseType,
                    loading: false,
                    error: null
                }
            });
        } catch (err) {
            this.setState({
                retireError: errorToString(err)
            });
        }

        this.setState({
            retireSubmitting: false
        });
    }

    @bind
    private openDeleteModal() {
        this.setState({ showDeletionModal: true });
    }

    @bind
    private closeDeleteModal() {
        this.setState({ showDeletionModal: false });
    }

    public render() {
        const { t, i18n } = this.props;
        const {
            id,
            loadedLicenseType: { loading, error, value: licenseType },
            showDeletionModal,
            retireSubmitting, retireError
        } = this.state;

        let licenseTypeContent = null;
        if (licenseType) {
            const { createdAt, name, durationInMonths, clientRole, retired, licenseData } = licenseType;

            const retireSubmissionValue = (
                <span>
                    <ErrorDisplay error={retireError} inline={true} />
                </span>
            );
            const retiredValue = retired
                ? <div>{t("yes")} <span className="button-box-inline"><InlineButton onClick={this.unretire} loading={retireSubmitting}>{t("unretire")}</InlineButton> {retireSubmissionValue}</span></div>
                : <div>{t("no")}  <span className="button-box-inline"><InlineButton onClick={this.retire} loading={retireSubmitting}>{t("retire")}</InlineButton> {retireSubmissionValue}</span></div>;

            licenseTypeContent = (
                <div>
                    <DetailDisplay elements={[
                        { key: "created", value: dateTimeToString(createdAt, i18n.language) },
                        { key: "name", value: name },
                        { key: "duration", value: t("months-with-count", { count: durationInMonths }) },
                        { key: "role", value: getClientRoleDisplayText(clientRole, t) },
                        { key: "retired?", value: retiredValue }
                    ]} />
                    {licenseData && (licenseData.length > 0)
                        ? (
                            <div>
                                <p>{t("currently-in-use-by")}:</p>
                                <ul className="item-list">
                                    {licenseData.map(data => {
                                        const { id: userId, fullName } = data.clientData.user;
                                        return <li key={userId}><Link to={routes.adminClientView(userId)}>{t("user-id-and-name", { id: userId, name: fullName })}</Link></li>;
                                    })}
                                </ul>
                            </div>
                        )
                        : (
                            <div className="section">
                                <span>{t("license-type-deletable-reason")}</span>
                                <span className="button-box-inline">
                                    <InlineButton className="is-danger" onClick={this.openDeleteModal}><FontAwesomeIcon icon={faTimesCircle} pull="left" /> {t("delete")}</InlineButton>
                                </span>
                            </div>
                        )}
                </div>
            );
        }

        return (
            <div>
                <Breadcrumbs routeKeys={["admin", "adminLicenseTypeOverview", id]} />
                {licenseType && <PageTitle>{licenseType.name} ({t("months-with-count", { count: licenseType.durationInMonths })}, {getClientRoleDisplayText(licenseType.clientRole, t)})</PageTitle>}
                {loading && <Loading />}
                <ErrorDisplay error={error} />
                {licenseTypeContent}
                {showDeletionModal && <DeleteLicenseTypeModal id={id} reload={this.load} close={this.closeDeleteModal} />}
            </div>
        );
    }
}

export default withTranslation()(ShowLicenseType);