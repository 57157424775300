export const hoursToMinutes = 60;
export const daysToMinutes = 24 * hoursToMinutes;
export const minutesToSeconds = 60;
export const hoursToSeconds = 60 * minutesToSeconds;
export const daysToSeconds = 24 * hoursToSeconds;
export const minutesToMS = 60000;

const timeBetweenReleasesDays = 7;
const licenseWillExpireWarningDays = 7;

export const constants = {
    mail: {
        subjectPrefixByLanguage: {
            en: "PharmaView Website: ",
            de: "PharmaView-Website: "
        },
        supportMail: "support@pharmaview-website.com"
    },
    resetLinkAvailabilityMinutes: 30,
    registrationCodeAvailabilityMinutes: 1 * daysToMinutes,
    timeBetweenReleasesDays,
    timeBetweenReleasesMinutes: timeBetweenReleasesDays * daysToMinutes,
    licenseWillExpireWarningDays,
    licenseWillExpireWarningMinutes: licenseWillExpireWarningDays * daysToMinutes,
    notificationCronjobIntervalMS: 10 * minutesToMS,
    logEntriesOverviewDashboardDays: 7,
    security: {
        login: {
            maxWrongAttemptsByIPPerDay: {
                points: 100,                      // More than 100 failed login attempts with this IP...
                duration: 1 * daysToSeconds,      // ...per day...
                blockDuration: 1 * daysToSeconds, // ...block for 1 day.
            },
            maxConsecutiveFailsByUsernameAndIP: {
                points: 10,                        // More than 10 failed login attempts with this IP/username combination...
                duration: 90 * daysToSeconds,      // ...per 90 days since first fail...
                blockDuration: 1 * hoursToSeconds, // ...block for 1 hour.
            }
        },
        publicDeviceAPI: {
            maxWrongAPIKey: {
                points: 1,
                duration: 1 * daysToSeconds,
                blockDuration: 1 * minutesToSeconds
            }
        }
    }
};