import * as React from "react";
import { inject } from "mobx-react";
import { AppStateStore } from "../../stores/appStateStore";
import { logout } from "../../communication/api";
import { bind } from "bind-decorator";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
    className: string;
}

interface IState {
    inProgress: boolean;
}

@inject("appStateStore")
class Logout extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            inProgress: false
        };
    }

    @bind
    private async logout() {
        if (this.state.inProgress)
            return;

        this.setState({ inProgress: true });
        await logout();

        // Set the user to null. This will also unmount this component (because by definition it should
        // only be shown when a user is logged in), so we don't need to set inProgress to false.
        this.props.appStateStore.setLoggedOutManually();
    }

    public render() {
        const { className, t } = this.props;

        return (
            <a className={className} onClick={this.logout}>{t("logout")}</a>
        );
    }
}

export default withTranslation()(Logout);