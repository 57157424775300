import * as React from "react";
import { match } from "react-router";
import ConfirmEmailShared from "../shared/ConfirmEmailShared";

interface IMatchParameters {
    code: string;
}

interface IProps {
    match: match<IMatchParameters>;
}

export default function ConfirmBackupEmail(props: IProps) {
    return <ConfirmEmailShared code={props.match.params.code} isBackupEmail={true} />;
}