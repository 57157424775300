import * as React from "react";
import { Formik, Form, Field } from "formik";
import { errorToString, changeOwnPassword } from "../../../communication/api";
import { ErrorDisplayForm } from "../ErrorDisplayForm";
import { ErrorDisplay } from "../ErrorDisplay";
import { AppStateStore } from "../../../stores/appStateStore";
import { inject, observer } from "mobx-react";
import { passwordChangeSchema } from "../../../../shared/definitions/validationSchemes/user";
import PasswordInput from "../PasswordInput";
import { BField, BLabel, BControl } from "../BulmaElements";
import { withTranslation, WithTranslation } from "react-i18next";

export type SetFieldValue = (value: string) => Promise<void>;

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
}

interface IState {
    formSubmissionError: string;
    changeSuccessful: boolean;
}

@inject("appStateStore")
@observer
class ChangeOwnPassword extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            formSubmissionError: "",
            changeSuccessful: false
        };
    }

    public render() {
        const { t } = this.props;
        const { changeSuccessful, formSubmissionError } = this.state;

        return (
            <Formik
                initialValues={{
                    password: "",
                    passwordConfirmation: ""
                }}
                validationSchema={passwordChangeSchema}
                onSubmit={async (values, formikActions) => {
                    this.setState({
                        formSubmissionError: null,
                        changeSuccessful: false
                    });

                    try {
                        const { password } = values;
                        await changeOwnPassword(password);
                        this.setState({
                            changeSuccessful: true
                        });
                    } catch (err) {
                        this.setState({
                            formSubmissionError: errorToString(err)
                        });
                    }

                    formikActions.setSubmitting(false);
                    formikActions.resetForm();
                }}
            >
                {({ errors, touched, isSubmitting, values }) => {
                    return (
                        <Form>
                            <BField>
                                <BLabel>{t("password")}</BLabel>
                                <BControl>
                                    <Field name="password" component={PasswordInput} disabled={isSubmitting} />
                                </BControl>
                                <ErrorDisplayForm error={touched.password && errors.password} />
                            </BField>
                            <BField>
                                <BLabel>{t("confirm-password")}</BLabel>
                                <BControl>
                                    <Field className="input" name="passwordConfirmation" type="password" disabled={isSubmitting} />
                                </BControl>
                                <ErrorDisplayForm error={touched.passwordConfirmation && errors.passwordConfirmation} />
                            </BField>
                            <BField>
                                <BControl>
                                    <button
                                        className={"button is-primary" + (isSubmitting ? " is-loading" : "")}
                                        type="submit"
                                        disabled={isSubmitting}>
                                        {t("change-password-submit")}
                                    </button>
                                </BControl>
                            </BField>
                            <ErrorDisplay error={formSubmissionError} />
                            {changeSuccessful && <BField>{t("change-password-success")}</BField>}
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default withTranslation()(ChangeOwnPassword);