import * as React from "react";

interface IProps {
    children?: any;
    className?: string;
}

const DivWithClass = (className: string) => {
    return (props: IProps) => <div className={className + (props.className ? " " + props.className : "")}>{props.children}</div>;
};

export const BField = DivWithClass("field");
export const BLabel = DivWithClass("label");
export const BControl = DivWithClass("control");
export const BSelect = DivWithClass("select");