import * as React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import App from "./components/App";
import { Provider } from "mobx-react";
import { stores } from "./stores";
import { Router } from "react-router-dom";
import browserHistory from "./utils/browserHistory";
import "./i18n";
import { i18nLoaded } from "./i18n";

async function init() {
    await i18nLoaded;

    const rootEl = document.getElementById("root");

    render(
        <AppContainer>
            <Provider {...stores}>
                <Router history={browserHistory}>
                    <App />
                </Router>
            </Provider>
        </AppContainer>,
        rootEl
    );
}

// tslint:disable-next-line: no-floating-promises
init();