import * as React from "react";
import { inject } from "mobx-react";
import { AppStateStore } from "../../stores/appStateStore";
import { bind } from "bind-decorator";
import Login from "./Login";
import { ModalDialog } from "../shared/ModalDialog";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
}

interface IState {
}

@inject("appStateStore")
class LoginModal extends React.Component<IProps, IState> {
    @bind
    private closeModal() {
        this.props.appStateStore.closeLoginModal();
    }

    public render() {
        const { t } = this.props;

        return (
            <ModalDialog
                title={t("login-title")}
                close={this.closeModal}
            >
                <Login redirectAfterLogin={false} />
            </ModalDialog>
        );
    }
}

export default withTranslation()(LoginModal);