import * as React from "react";
import { ModalCard } from "./ModalCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IButtonDescription {
    callback: () => void;
    icon?: IconProp;
    text: string;
    className?: string;
    isLoading?: boolean;
}

interface IProps {
    children: any;
    title: string;
    buttons?: IButtonDescription[];
    close: () => void;
    closeDisabled?: boolean;
    closeInvisible?: boolean;
    buttonsDisabled?: boolean;
    additionalFooterSection?: JSX.Element;
}

export function ModalDialog(props: IProps) {
    const { children, title, buttons, close, closeDisabled, closeInvisible, buttonsDisabled, additionalFooterSection } = props;

    return (
        <ModalCard>
            <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                {!closeInvisible && <button className="delete" aria-label="close" onClick={close} disabled={closeDisabled}></button>}
            </header>
            <section className="modal-card-body">
                {children}
            </section>
            <footer className="modal-card-foot" style={{ display: "block" }}>
                {buttons && buttons.map(({ callback, icon, text, className, isLoading }, index) => <button className={"button " + className + (isLoading ? " is-loading" : "")} onClick={callback} disabled={buttonsDisabled} key={index}>{icon && <FontAwesomeIcon icon={icon} pull="left" />} {text}</button>)}
                {additionalFooterSection}
            </footer>
        </ModalCard>
    );
}