import * as React from "react";
import { match } from "react-router";
import { IInvitation } from "../../../../shared/definitions/models/IInvitation";
import { getInvitation, errorToString, resendInvitationEmail } from "../../../communication/api";
import { Link } from "react-router-dom";
import { dateTimeToString } from "../../../../shared/utils/dateTimeToString";
import { routes } from "../../../../shared/config/routes";
import { IAsyncLoadedObject, load } from "../../../utils/asyncLoader";
import { Loading } from "../../shared/Loading";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import { DetailDisplay } from "../../shared/DetailDisplay";
import bind from "bind-decorator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import DeleteInvitationModal from "./DeleteInvitationModal";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageTitle from "../../shared/PageTitle";
import InlineButton from "../../shared/UserDetails/InlineButton";
import { WithTranslation, withTranslation } from "react-i18next";
import { getClientRoleDisplayText } from "../../../utils/getClientRoleDisplayText";

interface IMatchParameters {
    id: string;
}

interface IProps extends WithTranslation {
    match: match<IMatchParameters>;
}

interface IState {
    id: number;
    loadedInvitation: IAsyncLoadedObject<IInvitation>;
    resendingEmail: boolean;
    resendingEmailSuccess: boolean;
    resendingEmailError: string;
    showDeletionModal: boolean;
}

class ShowInvitation extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            id: Number.parseInt(props.match.params.id, 10),
            loadedInvitation: {
                error: "",
                loading: false,
                value: null
            },
            resendingEmail: false,
            resendingEmailSuccess: false,
            resendingEmailError: null,
            showDeletionModal: false
        };
    }

    public async componentDidMount() {
        await this.load();
    }

    @bind
    public async load() {
        this.setState({ showDeletionModal: false });
        await load(this, "loadedInvitation", () => getInvitation(this.state.id));
    }

    @bind
    private async resendEmail() {
        this.setState({
            resendingEmail: true,
            resendingEmailSuccess: false,
            resendingEmailError: null
        });

        try {
            await resendInvitationEmail(this.state.id);
            this.setState({
                resendingEmailSuccess: true
            });
        } catch (err) {
            this.setState({
                resendingEmailError: errorToString(err)
            });
        }

        this.setState({
            resendingEmail: false
        });
    }

    @bind
    private openDeleteModal() {
        this.setState({ showDeletionModal: true });
    }

    @bind
    private closeDeleteModal() {
        this.setState({ showDeletionModal: false });
    }

    public render() {
        const { t, i18n } = this.props;
        const {
            id,
            loadedInvitation: { loading, error, value: invitation },
            resendingEmail, resendingEmailSuccess, resendingEmailError,
            showDeletionModal
        } = this.state;

        let invitationContent = null;
        if (invitation) {
            const { createdAt, email, clientRole, invitationCode, language, userId, user } = invitation;
            invitationContent = (
                <div>
                    <DetailDisplay elements={[
                        { key: "created", value: dateTimeToString(createdAt, i18n.language) },
                        { key: "email", value: email },
                        { key: "role", value: getClientRoleDisplayText(clientRole, t) },
                        { key: "invitation-code", value: <a href={routes.signUpWithInvitationCode(invitationCode)}>{invitationCode}</a> },
                        { key: "language", value: t("language-" + language) },
                        { key: "accepted?", value: userId ? <Link to={routes.adminClientView(userId)}>Client #{userId}: {user.fullName}</Link> : t("not-yet") }
                    ]} />
                    {!userId && (
                        <div>
                            <div className="section">
                                <div className="button-box-inline">
                                    <InlineButton onClick={this.resendEmail} loading={resendingEmail}>{t("resend-invitation-email")}</InlineButton>
                                </div>
                                {resendingEmailSuccess && <span>{t("resend-invitation-email-success")}</span>}
                                <ErrorDisplay error={resendingEmailError} inline={true} />
                                {resendingEmailError && (
                                    <span className="button-box-inline">
                                        <InlineButton onClick={this.load}><FontAwesomeIcon icon={faSync} pull="left" /> {t("refresh-invitation")}</InlineButton>
                                    </span>
                                )}
                            </div>
                            <div className="section">
                                <span>{t("invitation-deletable-reason")} </span>
                                <span className="button-box-inline">
                                    <InlineButton className="is-danger" onClick={this.openDeleteModal}><FontAwesomeIcon icon={faTimesCircle} pull="left" />{t("delete")}</InlineButton>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div>
                <Breadcrumbs routeKeys={["admin", "adminInvitationOverview", id]} />
                {invitation && <PageTitle>{t("invitation-title", { email: invitation.email })}</PageTitle>}
                {loading && <Loading />}
                <ErrorDisplay error={error} />
                {invitationContent}
                {showDeletionModal && <DeleteInvitationModal id={id} reload={this.load} close={this.closeDeleteModal} />}
            </div>
        );
    }
}

export default withTranslation()(ShowInvitation);