import { IUser } from "../../shared/definitions/models/IUser";
import { observable, action } from "mobx";
import browserHistory from "../utils/browserHistory";
import i18n from "../i18n";

export class AppStateStore {
    @observable public user: IUser;
    @observable public showLoginModal: boolean;
    @observable public userHasBeenLoggedOut: boolean;
    public lastUrlBeforeLoginRedirect: string;
    public loggedOutManually: boolean;

    @action.bound
    public setUser(user: IUser) {
        // Set language if a new user logged in and this new user has a set language
        if (user && (!this.user || (this.user.id !== user.id)) && user.language) {
            // tslint:disable-next-line: no-floating-promises
            i18n.changeLanguage(user.language);
        }

        this.user = user;

        if (user) {
            this.userHasBeenLoggedOut = false;
            this.showLoginModal = false;
            this.loggedOutManually = false;
        }
    }

    @action.bound
    public setUserHasBeenLoggedOut() {
        if (this.showLoginModal)
            return;

        this.showLoginModal = true;
        this.userHasBeenLoggedOut = true;
    }

    @action.bound
    public closeLoginModal() {
        if (!this.showLoginModal)
            return;

        this.showLoginModal = false;
        if (this.userHasBeenLoggedOut) {
            this.setUser(null);
        }
    }

    public setLoggedOutManually() {
        this.loggedOutManually = true;
        this.lastUrlBeforeLoginRedirect = null;
        this.setUser(null);
    }

    public saveCurrentUrlBeforeRedirectingToLogin() {
        if (this.loggedOutManually)
            return;

        this.lastUrlBeforeLoginRedirect = browserHistory.location.pathname;
    }

    public get hasSavedUrlForAfterLogin(): boolean {
        return !!this.lastUrlBeforeLoginRedirect;
    }

    public restoreUrlAfterLogin() {
        if (this.hasSavedUrlForAfterLogin) {
            browserHistory.push(this.lastUrlBeforeLoginRedirect);
            this.lastUrlBeforeLoginRedirect = null;
        }
    }
}
