import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    error: string | JSX.Element;
    translate?: boolean;
    inline?: boolean;
}

export function ErrorDisplay({ error, translate, inline }: IProps) {
    const { t } = useTranslation();

    if (!error)
        return null;

    const errorContent = translate ? t(error as string) : error;

    return inline
        ? <span className="error">{errorContent}</span>
        : <p className="section error">{errorContent}</p>;
}