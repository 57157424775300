import { Component } from "react";
import { connect, FormikValues, FormikProps } from "formik";

interface IProps {
    onChange: (values: FormikValues, nextValues: FormikValues) => void;
}

interface IPropsEnhanced extends IProps {
    formik: FormikProps<FormikValues>;
}

class Effect extends Component<IPropsEnhanced> {
    public componentWillReceiveProps(nextProps) {
        const { values, touched, errors, isSubmitting } = this.props.formik;
        const {
            values: nextValues,
            touched: nextTouched,
            errors: nextErrors,
            isSubmitting: nextIsSubmitting,
        } = nextProps.formik;
        if (nextProps.formik !== this.props.formik) {
            this.props.onChange(
                {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                },
                {
                    values: nextValues,
                    touched: nextTouched,
                    errors: nextErrors,
                    isSubmitting: nextIsSubmitting,
                },
            );
        }
    }

    public render() {
        return null;
    }
}

export default connect<IProps>(Effect);