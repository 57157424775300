import axios, { CancelTokenSource, CancelToken } from "axios";
import { createCancelTokenSource, errorToString } from "../communication/api";
import { SortingRule, Filter } from "react-table";

interface IPaginatedReactTableComponent {
    loadStateString: string;
    cancelTokenSource: CancelTokenSource;
}

export function loadPaginatedReactTableData<TObject, TState>(reactComponent: React.Component<any, TState> & IPaginatedReactTableComponent, objectPropertyName: keyof TState, loadingFunction: (limit: number, offset: number, sortingRules: SortingRule[], filters: Filter[], cancelToken: CancelToken) => Promise<TObject>) {
    const reactComponentCast = reactComponent as (React.Component<any, any> & IPaginatedReactTableComponent);

    // Wait until queued setState calls are done
    reactComponentCast.setState({}, async () => {
        const { pageSize, page, sorted, filtered } = reactComponentCast.state;

        const newLoadStateString = JSON.stringify({ pageSize, page, sorted, filtered });

        // HACK tw. "Fix" for the duplicate requests described in reactComponent bug: https://github.com/tannerlinsley/react-table/issues/1333
        if (reactComponentCast.state[objectPropertyName].loading) {
            if (newLoadStateString === reactComponentCast.loadStateString) {
                //console.log("Ignoring", newLoadStateString);
                // We are still loading and it's the same as the last request? Just return and don't make a new one.
                return;
            } else {
                // We are still loading and the request changed? Stop the previous request and start a new one.
                reactComponentCast.cancelTokenSource.cancel();
                reactComponentCast.cancelTokenSource = createCancelTokenSource();
                //console.log("Cancelling previous request");
            }
        }

        reactComponentCast.loadStateString = newLoadStateString;

        const limit = pageSize;
        const offset = pageSize * page;
        await load(reactComponentCast, objectPropertyName, () => loadingFunction(limit, offset, sorted, filtered, reactComponentCast.cancelTokenSource.token), false);
    });
}

export async function load<TObject, TState>(reactComponent: React.Component<any, TState>, objectPropertyName: keyof TState, loadingFunction: () => Promise<TObject>, clearValueWhileLoading: boolean = true): Promise<{ error?: string, value?: TObject, cancelled?: boolean }> {
    // HACK tw: The mention of TState in the signature is there to make objectPropertyName be a type-safe keyof,
    // but sadly neither reactComponent.state nor reactComponent.setState like that. I don't know of any way to
    // fix that, and I know that all my calls to reactComponent.state and reactComponent.setState will work, so
    // I'm casting it to a component that can take any state to turn off the TypeScript complaints.
    const reactComponentCast = reactComponent as React.Component<any, any>;

    reactComponentCast.setState((state, props) => ({
        [objectPropertyName]: {
            loading: true,
            error: null,
            value: clearValueWhileLoading ? null : state[objectPropertyName].value
        }
    }));

    try {
        const obj = await loadingFunction();
        reactComponentCast.setState({
            [objectPropertyName]: {
                loading: false,
                value: obj
            }
        });
        return {
            value: obj
        };
    } catch (err) {
        if (axios.isCancel(err)) {
            //console.log("Request cancelled!");
            return {
                cancelled: true
            };
        }

        const errorString = errorToString(err);

        reactComponentCast.setState({
            [objectPropertyName]: {
                loading: false,
                error: errorString
            }
        });
        return {
            error: errorString
        };
    }
}

export interface IAsyncLoadedObject<T> {
    loading: boolean;
    error: string;
    value: T;
}
