import * as React from "react";
import { routes } from "../../../../shared/config/routes";
import { Formik, Form, Field } from "formik";
import { ClientRole } from "../../../../shared/definitions/clientRole";
import { createLicenseType, errorToString } from "../../../communication/api";
import { createLicenseTypeSchema } from "../../../../shared/definitions/validationSchemes/licenseType";
import { ErrorDisplayForm } from "../../shared/ErrorDisplayForm";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import LinkButton from "../../shared/LinkButton";
import { getClientRoleDisplayText } from "../../../utils/getClientRoleDisplayText";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageTitle from "../../shared/PageTitle";
import { BLabel, BControl, BField, BSelect } from "../../shared/BulmaElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
}

interface IState {
    formSubmissionError: string;
    successId: number;
}

function createDefaultState() {
    return {
        formSubmissionError: "",
        successId: null
    };
}

class CreateLicenseType extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = createDefaultState();
    }

    private reset(resetForm: () => void): void {
        resetForm();
        this.setState(createDefaultState());
    }

    public render() {
        const { t } = this.props;
        const { formSubmissionError, successId } = this.state;

        return (
            <div>
                <Breadcrumbs routeKeys={["admin", "adminLicenseTypeOverview", "adminLicenseTypeCreate"]} />
                <PageTitle>{t("create-license-type-title")}</PageTitle>
                <Formik
                    initialValues={{
                        name: "",
                        durationInMonths: 1,
                        clientRole: ClientRole.DeviceUser
                    }}
                    validationSchema={createLicenseTypeSchema}
                    onSubmit={async (values, formikActions) => {
                        this.setState({ formSubmissionError: null });
                        try {
                            const { name, durationInMonths, clientRole } = values;
                            const licenseType = await createLicenseType(name, durationInMonths, clientRole as ClientRole);
                            this.setState({
                                successId: licenseType.id
                            });
                            //browserHistory.push(routes.adminLicenseTypeView(licenseType.id));
                        } catch (err) {
                            this.setState({ formSubmissionError: errorToString(err) });
                            formikActions.setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting, resetForm }) => (
                        <Form>
                            <BField>
                                <BLabel>{t("name")}</BLabel>
                                <BControl>
                                    <Field className="input" name="name" />
                                </BControl>
                                <ErrorDisplayForm error={touched.name && errors.name} />
                            </BField>
                            <BField>
                                <BLabel>{t("duration-months")}</BLabel>
                                <BControl>
                                    <Field className="input" name="durationInMonths" type="number" min="1" />
                                </BControl>
                                <ErrorDisplayForm error={touched.durationInMonths && errors.durationInMonths} />
                            </BField>
                            <BField>
                                <BLabel>{t("role")}</BLabel>
                                <BControl>
                                    <BSelect>
                                        <Field name="clientRole" component="select" placeholder="">
                                            <option value="" disabled>{t("select-role")}</option>
                                            <option value={ClientRole.DeviceUser}>{getClientRoleDisplayText(ClientRole.DeviceUser, t)}</option>
                                            <option value={ClientRole.ContentCreator}>{getClientRoleDisplayText(ClientRole.ContentCreator, t)}</option>
                                        </Field>
                                    </BSelect>
                                </BControl>
                                <ErrorDisplayForm error={touched.clientRole && errors.clientRole} />
                            </BField>
                            <BField>
                                <BControl>
                                    <button className={"button is-primary" + ((isSubmitting && !successId) ? " is-loading" : "")} type="submit" disabled={isSubmitting}>{t("create-license-type-submit")}</button>
                                </BControl>
                            </BField>
                            <ErrorDisplay error={formSubmissionError} />
                            {successId && (
                                <div>
                                    <BField>
                                        {t("create-license-type-success")}
                                    </BField>
                                    <BField className="is-grouped">
                                        <BControl>
                                            <LinkButton className="button" to={routes.adminLicenseTypeView(successId)}><FontAwesomeIcon icon={faExternalLinkAlt} pull="left" /> {t("open-license-type")}</LinkButton>
                                        </BControl>
                                        <BControl>
                                            <button className="button" onClick={() => this.reset(resetForm)}><FontAwesomeIcon icon={faPlus} pull="left" /> {t("create-another-license-type")}</button>
                                        </BControl>
                                    </BField>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withTranslation()(CreateLicenseType);