import * as React from "react";
import { IUser } from "../../../../shared/definitions/models/IUser";
import { observer } from "mobx-react";
import { UserUpdated } from "./ChangeUserActiveState";
import { errorToString, setClientLicense, prolongClientLicense } from "../../../communication/api";
import { ErrorDisplay } from "../ErrorDisplay";
import { Formik, Form, Field, validateYupSchema, yupToFormErrors } from "formik";
import { ErrorDisplayForm } from "../ErrorDisplayForm";
import { ILicenseType } from "../../../../shared/definitions/models/ILicenseType";
import { dateToInputFieldString, daysInMonth, dateComponentsToInputFieldString } from "../../../utils/dateUtilities";
import { setLicenseSchemaClient } from "../../../../shared/definitions/validationSchemes/user";
import Effect from "../Effect";
import { dateTimeToString } from "../../../../shared/utils/dateTimeToString";
import PageTitle from "../PageTitle";
import { BField, BSelect, BLabel, BControl } from "../BulmaElements";
import InlineButton from "./InlineButton";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    user: IUser;
    userUpdated: UserUpdated;
    reloadUser: () => void;
    licenseTypes: ILicenseType[];
}

interface IState {
    formSubmissionError: JSX.Element;
    successMessage: string;
}

@observer
class UserDetailsSetLicense extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            formSubmissionError: null,
            successMessage: null
        };
    }

    public render() {
        const { t, i18n, user: { id: userId, clientData: { licenseData } }, licenseTypes, userUpdated, reloadUser } = this.props;
        const { formSubmissionError, successMessage } = this.state;

        const hasActiveLicense = licenseData.licenseType && !licenseData.hasLicenseExpired;
        const now = new Date();
        const calculatedStartDateString = hasActiveLicense
            ? dateToInputFieldString(new Date(licenseData.endDate.getTime() + 1000)) // 1 second later - new day
            : dateComponentsToInputFieldString(now.getFullYear(), now.getMonth() + 1, now.getDate(), 0, 0, 0);

        // The form should be completely reloaded if license data that might be used to calculate parts of the form changes
        const reloadKey = "" + (licenseData.licenseType != null) + (licenseData.endDate && licenseData.endDate.getTime());

        return (
            <div>
                <PageTitle subtitle={2}>{hasActiveLicense ? t("prolong-license-title") : t("set-license-title")}</PageTitle>
                <Formik
                    key={reloadKey}
                    initialValues={{
                        licenseTypeIndex: -1,
                        startDate: calculatedStartDateString,
                        autoCalculateEndDate: true,
                        endDate: ""
                    }}
                    validate={values => {
                        try {
                            // tslint:disable-next-line: no-floating-promises
                            validateYupSchema(values, setLicenseSchemaClient, true);
                        } catch (err) {
                            return yupToFormErrors(err);
                        }

                        const errors: any = {};

                        const startDateTime = new Date(values.startDate).getTime();
                        const endDateTime = new Date(values.endDate).getTime();

                        if (endDateTime < Date.now()) {
                            errors.endDate = "end-date-must-be-in-future";
                        } else if (endDateTime <= startDateTime) {
                            errors.endDate = "end-date-must-be-after-start-date";
                        }

                        return errors;
                    }}
                    onSubmit={async (values, formikActions) => {
                        this.setState({
                            formSubmissionError: null,
                            successMessage: null
                        });
                        try {
                            const { licenseTypeIndex, startDate, endDate } = values;
                            const licenseTypeId = licenseTypes[licenseTypeIndex].id;
                            if (hasActiveLicense) {
                                const updatedUser = await prolongClientLicense(userId, licenseTypeId, new Date(endDate));
                                userUpdated(updatedUser);
                                const updatedEndDate = updatedUser.clientData.licenseData.endDate;
                                this.setState({
                                    successMessage: t("prolong-license-success", {
                                        updatedEndDate: dateTimeToString(updatedEndDate, i18n.language)
                                    })
                                });
                            } else {
                                const updatedUser = await setClientLicense(userId, licenseTypeId, new Date(startDate), new Date(endDate));
                                userUpdated(updatedUser);
                                const { startDate: updatedStartDate, endDate: updatedEndDate } = updatedUser.clientData.licenseData;
                                this.setState({
                                    successMessage: t("set-license-success", {
                                        updatedStartDate: dateTimeToString(updatedStartDate, i18n.language),
                                        updatedEndDate: dateTimeToString(updatedEndDate, i18n.language)
                                    })
                                });
                            }
                        } catch (err) {
                            this.setState({
                                formSubmissionError: (
                                    <span>
                                        <span>{t("server-error", { error: errorToString(err) })} {t("maybe-license-was-updated")}</span>
                                        <InlineButton onClick={reloadUser}>{t("reload")}</InlineButton>
                                    </span>
                                )
                            });
                        }
                        formikActions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, isSubmitting, values }) => {
                        return (
                            <Form>
                                <Effect onChange={(oldFormikState, newFormikState) => {
                                    const currentValues = newFormikState.values as any;

                                    const currentStartDate = currentValues.startDate as string;

                                    if (hasActiveLicense && (calculatedStartDateString !== currentStartDate)) {
                                        newFormikState.values.startDate = calculatedStartDateString;
                                    }

                                    if (!currentValues.autoCalculateEndDate)
                                        return;

                                    const currentEndDate = currentValues.endDate as string;
                                    const currentLicenseTypeIndex = currentValues.licenseTypeIndex as number;
                                    let calculatedEndDateString = "";

                                    if (currentStartDate && (currentLicenseTypeIndex !== -1)) {
                                        calculatedEndDateString = addMonthsToDateString(currentStartDate, licenseTypes[currentLicenseTypeIndex].durationInMonths);
                                    }

                                    if (calculatedEndDateString !== currentEndDate) {
                                        newFormikState.values.endDate = calculatedEndDateString;
                                    }
                                }}
                                />
                                <BField>
                                    <BLabel>{t("license-type")}</BLabel>
                                    <BControl>
                                        <BSelect>
                                            <Field name="licenseTypeIndex" component="select" placeholder="">
                                                <option value="-1" disabled>{t("select-license-type")}</option>
                                                {licenseTypes.map((licenseType, index) => (
                                                    <option key={licenseType.id} value={index}>
                                                        {licenseType.name} ({t("months-with-count", { count: licenseType.durationInMonths })})
                                                </option>
                                                ))}
                                            </Field>
                                        </BSelect>
                                    </BControl>
                                    <ErrorDisplayForm error={touched.licenseTypeIndex && errors.licenseTypeIndex} />
                                </BField>
                                <BField>
                                    <BLabel>{t("start-date")}</BLabel>
                                    <BControl>
                                        <Field className="input" name="startDate" type="datetime-local" step="1" disabled={hasActiveLicense} />
                                    </BControl>
                                    <ErrorDisplayForm error={errors.startDate} />
                                </BField>
                                <BField>
                                    <label className="checkbox">
                                        <Field name="autoCalculateEndDate" type="checkbox" checked={values.autoCalculateEndDate} /> {t("calculate-end-date")}
                                    </label>
                                </BField>
                                <BField>
                                    <BLabel>{t("end-date")}</BLabel>
                                    <BControl>
                                        <Field className="input" name="endDate" type="datetime-local" step="1" disabled={values.autoCalculateEndDate} />
                                    </BControl>
                                    {!values.autoCalculateEndDate && <ErrorDisplayForm error={touched.endDate && errors.endDate} />}
                                </BField>
                                <BField>
                                    <BControl>
                                        <button
                                            className={"button is-primary" + (isSubmitting ? " is-loading" : "")}
                                            type="submit"
                                            disabled={isSubmitting}>
                                            {hasActiveLicense ? t("prolong-license-submit") : t("set-license-submit")}
                                        </button>
                                    </BControl>
                                </BField>
                                <ErrorDisplay error={formSubmissionError} />
                                {successMessage && <BField>{successMessage}</BField>}
                            </Form>
                        );
                    }}
                </Formik>
            </div >
        );
    }
}

export default withTranslation()(UserDetailsSetLicense);

export function addMonthsToDateString(dateString: string, addMonths: number) {
    const date = new Date(dateString);
    let year = date.getFullYear();
    let monthZeroBased = date.getMonth();
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // Add the license duration
    monthZeroBased += addMonths;
    seconds--;

    // Revalidate the date
    if (seconds < 0) {
        seconds += 60;
        minutes--;
    }

    if (minutes < 0) {
        minutes += 60;
        hours--;
    }

    if (hours < 0) {
        hours += 24;
        day--;
    }

    if (day === 0) {
        monthZeroBased--;
        day = 31;
    }

    year += Math.floor(monthZeroBased / 12);

    monthZeroBased %= 12;

    const month = monthZeroBased + 1;
    day = Math.min(day, daysInMonth(month, year));

    // Make string
    return dateComponentsToInputFieldString(year, month, day, hours, minutes, seconds);
}