import * as React from "react";
import { ILogEntry } from "../../../../shared/definitions/models/ILogEntry";
import { createCancelTokenSource, getLogEntries } from "../../../communication/api";
import bind from "bind-decorator";
import { IAsyncLoadedObject, loadPaginatedReactTableData } from "../../../utils/asyncLoader";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import { IGetAllPaginatedResult } from "../../../../shared/definitions/apiResults/IGetAllPaginatedResult";
import ReactTable, { Filter, SortingRule, RowInfo } from "react-table";
import "react-table/react-table.css";
import { CancelTokenSource } from "axios";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageTitle from "../../shared/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { WithTranslation, withTranslation } from "react-i18next";
import { dateTimeToString } from "../../../../shared/utils/dateTimeToString";
import LogEntriesInfo from "./LogEntriesInfo";
import { match } from "react-router";
import { reactTableTranslationProps } from "../../../utils/reactTableTranslationProps";

interface IMatchParameters {
    ip: string;
}

interface IProps extends WithTranslation {
    match: match<IMatchParameters>;
}

interface IState {
    loadedLogEntriesPagination: IAsyncLoadedObject<IGetAllPaginatedResult<ILogEntry>>;
    page: number;
    pageSize: number;
    sorted: SortingRule[];
    filtered: Filter[];
}

const defaultPageSize = 10;

class LogEntriesOverview extends React.Component<IProps, IState> {
    public cancelTokenSource: CancelTokenSource;
    public loadStateString: string;
    public previousAmountOfPages: number;

    constructor(props: IProps) {
        super(props);

        this.cancelTokenSource = createCancelTokenSource();

        const filtered = new Array<Filter>();

        const ipParam = this.props.match.params.ip;
        if (ipParam) {
            filtered.push({
                id: "ip",
                value: ipParam
            });
        }

        this.state = {
            loadedLogEntriesPagination: {
                error: "",
                loading: false,
                value: null
            },
            page: 0,
            pageSize: defaultPageSize,
            sorted: [{ id: "id", desc: true }],
            filtered
        };

        this.previousAmountOfPages = 0;
    }

    @bind
    public load() {
        loadPaginatedReactTableData(this, "loadedLogEntriesPagination", getLogEntries);
    }

    // Called when the page index is changed by the logEntry
    @bind
    private onPageChange(page: number) {
        this.setState({ page });
    }

    // Called when the pageSize is changed by the logEntry. The resolve page is also sent to maintain approximate position in the data
    @bind
    private onPageSizeChange(pageSize: number, page: number) {
        this.setState({ pageSize, page });
    }

    // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
    @bind
    private onSortedChange(sorted: SortingRule[], column: any, additive: boolean) {
        this.setState({
            sorted,
            page: 0
        });
    }

    // Called when a logEntry enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
    @bind
    private onFilteredChange(filtered: Filter[], column: any, value: any) {
        this.setState({
            filtered,
            page: 0
        });
    }

    @bind
    private resetFilters() {
        this.setState({
            filtered: [],
            page: 0
        }, () => this.load());
    }

    public render() {
        const { t, i18n } = this.props;
        const { page, pageSize, sorted, filtered } = this.state;
        const { loading, error, value: logEntriesPagination } = this.state.loadedLogEntriesPagination;
        let logEntries = [];
        let pages = this.previousAmountOfPages;
        if (logEntriesPagination) {
            logEntries = logEntriesPagination.rows;

            pages = Math.ceil(logEntriesPagination.totalCount / pageSize);
            this.previousAmountOfPages = pages;
        }

        return (
            <div>
                <Breadcrumbs routeKeys={["admin", "adminLogEntriesOverview"]} />
                <PageTitle>{t("log-entries")}</PageTitle>
                <ErrorDisplay error={error} />
                <ReactTable
                    columns={[
                        {
                            Header: t("id"),
                            accessor: "id",
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            width: 50
                        },
                        {
                            Header: t("date"),
                            id: "createdAt",
                            accessor: (logEntry: ILogEntry) => dateTimeToString(logEntry.createdAt, i18n.language),
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            width: 200
                        },
                        {
                            Header: t("severity"),
                            id: "severity",
                            accessor: (logEntry: ILogEntry) => t("severity-" + logEntry.severity),
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            width: 100
                        },
                        {
                            Header: t("ip"),
                            accessor: "ip",
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            width: 110
                        },
                        {
                            Header: t("log-entry-username"),
                            accessor: "username",
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            width: 150
                        },
                        {
                            Header: t("error-type"),
                            accessor: "errorType",
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            width: 170
                        },
                        {
                            Header: t("message"),
                            accessor: "message",
                            className: "react-table-display-field-fully",
                            minWidth: 300
                        },
                        {
                            Header: t("url"),
                            accessor: "url",
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            minWidth: 100
                        },
                        {
                            Header: t("user-agent"),
                            accessor: "userAgent",
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            minWidth: 100
                        },
                        {
                            Header: "",
                            id: "",
                            sortable: false,
                            Filter: () => <button className="button is-small" onClick={this.resetFilters}>{t("reset-filters")}</button>,
                            Cell: row => <span title={row.value}>{row.value}</span>,
                            width: 140,
                            maxWidth: 140
                        }
                    ]}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={logEntries}
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    loadingText={t("loading")}
                    onFetchData={this.load} // Request new data when things change
                    filterable
                    defaultPageSize={defaultPageSize}
                    className="-striped -highlight"

                    getTrProps={(state: any, rowInfo: RowInfo) => {
                        if (!rowInfo)
                            return {};

                        return {
                            className: "severity-" + (rowInfo.original as ILogEntry).severity
                        };
                    }}

                    page={page}
                    onPageChange={this.onPageChange}

                    pageSize={pageSize}
                    onPageSizeChange={this.onPageSizeChange}

                    sorted={sorted}
                    onSortedChange={this.onSortedChange}

                    filtered={filtered}
                    onFilteredChange={this.onFilteredChange}

                    {...reactTableTranslationProps(t)}
                />
                <button className={"button is-pulled-right" + (loading ? " is-loading" : "")} onClick={this.load} disabled={loading}><FontAwesomeIcon icon={faSync} pull="left" /> {t("refresh")}</button>
                <LogEntriesInfo />
            </div>
        );
    }
}

export default withTranslation()(LogEntriesOverview);