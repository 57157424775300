import * as React from "react";

interface IProps {
    children?: any;
    subtitle?: number;
}

export default function PageTitle(props: IProps) {
    const { children, subtitle } = props;
    const baseNumber = 3;
    if (subtitle) {
        return <h2 className={"subtitle is-" + (baseNumber + subtitle)}>{children}</h2>;
    } else {
        return <h1 className={"title is-" + baseNumber}>{children}</h1>;
    }
}