import React from "react";
import { useTranslation } from "react-i18next";

export function ReactTableDropdownFilter({ filter, onChange, options }) {
    const { t } = useTranslation();

    return (
        <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
        >
            <option value="">{t("filter-show-all")}</option>
            {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
        </select>
    );
}

export function createReactTableDropdownFilterFunction(options) {
    return ({ filter, onChange }) => <ReactTableDropdownFilter filter={filter} onChange={onChange} options={options} />;
}