export const defaultLanguage = "en";

interface ILanguage {
    nativeName: string;
    dateTimeFormat: Intl.DateTimeFormat;
}

interface ILanguageCollection {
    [key: string]: ILanguage;
}

export const languageData: ILanguageCollection = {
    de: {
        nativeName: "Deutsch",
        dateTimeFormat: createDateTimeFormat("de-DE")
    },
    en: {
        nativeName: "English",
        dateTimeFormat: createDateTimeFormat("en-US")
    }
};

export const validLanguages = Object.keys(languageData);

export function getValidLanguage(language: string) {
    return languageData[language] ? language : defaultLanguage;
}

function createDateTimeFormat(language: string) {
    return new Intl.DateTimeFormat(language, {
        year: "numeric", month: "2-digit", day: "2-digit",
        hour: "2-digit", minute: "2-digit", second: "2-digit",
        timeZoneName: "long"
    });
}
