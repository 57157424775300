import * as React from "react";
import { DetailDisplay } from "../DetailDisplay";
import { makeDetailDisplayUserDetailsEditableField, SetFieldValue } from "./UserDetailsEditableField";
import { observer } from "mobx-react";
import { userUpdateContentCreatorDataSchema } from "../../../../shared/definitions/validationSchemes/user";
import { IContentCreatorData } from "../../../../shared/definitions/models/IContentCreatorData";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    contentCreatorData: IContentCreatorData;
    setServerUrl: SetFieldValue;
    setServerUsername: SetFieldValue;
    setServerPassword: SetFieldValue;
}

interface IState {
}

@observer
class UserDetailsContentCreatorDataDisplay extends React.Component<IProps, IState> {
    public render() {
        const {
            t, setServerUrl, setServerUsername, setServerPassword,
            contentCreatorData: { contentCreatorCode, serverUrl, serverUsername, serverPassword }
        } = this.props;

        const elements = [];

        elements.push({ key: "content-server-id", value: contentCreatorCode });

        elements.push(makeDetailDisplayUserDetailsEditableField("content-server-url", serverUrl, setServerUrl, "serverUrl", false, false, userUpdateContentCreatorDataSchema));
        elements.push(makeDetailDisplayUserDetailsEditableField("content-server-username", serverUsername, setServerUsername, "serverUsername", false, false, userUpdateContentCreatorDataSchema));
        elements.push(makeDetailDisplayUserDetailsEditableField("content-server-password", serverPassword, setServerPassword, "serverPassword", false, true, userUpdateContentCreatorDataSchema));

        return (
            <div>
                <DetailDisplay elements={elements} />
                <p className="section">
                    {t("content-server-security-advice")}
                </p>
            </div>
        );
    }
}

export default withTranslation()(UserDetailsContentCreatorDataDisplay);