import * as React from "react";
import { newLineToLineBreak } from "../../../utils/newLineToLineBreak";
import bind from "bind-decorator";
import { Formik, Form, Field } from "formik";
import { errorToString } from "../../../communication/api";
import { ErrorDisplayForm } from "../ErrorDisplayForm";
import { ErrorDisplay } from "../ErrorDisplay";
import * as Yup from "yup";
import { BControl, BField } from "../BulmaElements";
import InlineButton from "./InlineButton";
import { withTranslation, WithTranslation } from "react-i18next";

export type SetFieldValue = (value: string) => Promise<void>;

interface IProps extends WithTranslation {
    value: string;
    setValue: SetFieldValue;
    formFieldId: string;
    multiline: boolean;
    password: boolean;
    validationSchema: Yup.ObjectSchema;
}

interface IState {
    formSubmissionError: string;
    editing: boolean;
}

class UserDetailsEditableFieldBase extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            formSubmissionError: "",
            editing: false
        };
    }

    @bind
    private switchToEditMode() {
        this.setState({
            editing: true
        });
    }

    @bind
    private cancelEditing() {
        this.setState({
            editing: false
        });
    }

    public render() {
        const { t } = this.props;
        const { value, multiline, password, setValue, formFieldId, validationSchema } = this.props;
        const { editing, formSubmissionError } = this.state;

        const fieldValidation = (Yup.reach(validationSchema, formFieldId) as unknown as Yup.StringSchema<string>);

        if (editing) {
            return (
                <Formik
                    initialValues={{
                        value: password ? "" : value
                    }}
                    onSubmit={async (values, formikActions) => {
                        this.setState({
                            formSubmissionError: null
                        });
                        try {
                            await setValue(values.value);
                            this.setState({
                                editing: false
                            });
                        } catch (err) {
                            this.setState({ formSubmissionError: errorToString(err) });
                            formikActions.setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting, values }) => (
                        <Form>
                            <BField className="is-grouped">
                                <BControl>
                                    <Field
                                        className={"is-small " + (multiline ? "textarea" : "input")}
                                        name="value"
                                        component={multiline ? "textarea" : undefined}
                                        type={password ? "password" : undefined}
                                        validate={async (fieldValue: string) => {
                                            try {
                                                await fieldValidation.validate(fieldValue);
                                                return null;
                                            } catch (err) {
                                                return err.message;
                                            }
                                        }}
                                    />
                                    <ErrorDisplayForm error={touched.value && errors.value} />
                                </BControl>
                                <BControl>
                                    <button
                                        className={"button is-small is-primary" + (isSubmitting ? " is-loading" : "")}
                                        type="submit"
                                        disabled={isSubmitting || (values.value === value) || (!values.value) || (touched.value && !!errors.value)}>
                                        {t("user-details-field-update")}
                                    </button>
                                    <button className="button is-small" type="button" onClick={this.cancelEditing} disabled={isSubmitting}>{t("cancel")}</button>
                                </BControl>
                                <ErrorDisplay error={formSubmissionError} inline={true} />
                            </BField>
                        </Form>
                    )}
                </Formik>
            );
        } else {
            return (
                <div>
                    {
                        multiline
                            ? <span style={{ display: "inline-block" }}>{newLineToLineBreak(value)}</span>
                            : <span>{value}</span>
                    }
                    <div className="button-box-inline">
                        <InlineButton onClick={this.switchToEditMode}>{t("edit")}</InlineButton>
                    </div>
                </div >
            );
        }
    }
}

const UserDetailsEditableField = withTranslation()(UserDetailsEditableFieldBase);

export default UserDetailsEditableField;

export function makeDetailDisplayUserDetailsEditableField(key: string, value: string, setValue: SetFieldValue, formFieldId: string, multiline: boolean, password: boolean, validationSchema: Yup.ObjectSchema) {
    return {
        key,
        value: <UserDetailsEditableField value={value} setValue={setValue} formFieldId={formFieldId} multiline={multiline} password={password} validationSchema={validationSchema} />
    };
}
