import * as React from "react";
import bind from "bind-decorator";
import { Formik, Form, Field } from "formik";
import { errorToString } from "../../../communication/api";
import { ErrorDisplay } from "../ErrorDisplay";
import { contentTypeIdRequired } from "../../../../shared/definitions/validationSchemes/user";
import { BField, BControl } from "../BulmaElements";
import InlineButton from "./InlineButton";
import { WithTranslation, withTranslation } from "react-i18next";

export type SetFieldNumberValue = (value: number) => Promise<void>;

interface IProps extends WithTranslation {
    value: number;
    setValue: SetFieldNumberValue;
}

interface IState {
    formSubmissionError: string;
    editing: boolean;
    settingNextFreeContentType: boolean;
}

class ClientContentTypeEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            formSubmissionError: "",
            editing: false,
            settingNextFreeContentType: false
        };
    }

    @bind
    private switchToEditMode() {
        this.setState({
            editing: true,
            formSubmissionError: ""
        });
    }

    @bind
    private cancelEditing() {
        this.setState({
            editing: false,
            formSubmissionError: ""
        });
    }

    @bind
    private async setNextFreeContentType() {
        this.setState({
            settingNextFreeContentType: true,
            formSubmissionError: ""
        });
        try {
            await this.props.setValue(undefined);
            this.setState({
                editing: false
            });
        } catch (err) {
            this.setState({ formSubmissionError: errorToString(err) });
        }
        this.setState({ settingNextFreeContentType: false });
    }

    public render() {
        const { t, value, setValue } = this.props;
        const { editing, formSubmissionError, settingNextFreeContentType } = this.state;

        if (editing) {
            return (
                <Formik
                    initialValues={{
                        value
                    }}
                    onSubmit={async (values, formikActions) => {
                        this.setState({
                            formSubmissionError: null
                        });
                        try {
                            await setValue(values.value);
                            this.setState({
                                editing: false
                            });
                        } catch (err) {
                            this.setState({ formSubmissionError: errorToString(err) });
                            formikActions.setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting, values }) => {
                        const invalidValue = (values.value === value) || (touched.value && !!errors.value);
                        return (
                            <Form>
                                <BField className="is-grouped">
                                    <BControl>
                                        <Field
                                            className="input is-small"
                                            name="value"
                                            type="number"
                                            min="0"
                                            validate={async (fieldValue: string) => {
                                                try {
                                                    await contentTypeIdRequired.validate(fieldValue);
                                                    return null;
                                                } catch (err) {
                                                    return err.message;
                                                }
                                            }}
                                        />
                                    </BControl>
                                    <ErrorDisplay error={touched.value && errors.value} inline={true} />
                                    <BControl>
                                        <button
                                            className={"button is-small is-primary" + (isSubmitting ? " is-loading" : "")}
                                            type="submit"
                                            disabled={isSubmitting || invalidValue}>
                                            {t("user-details-field-update")}
                                        </button>
                                        <button className="button is-small" type="button" onClick={this.cancelEditing} disabled={isSubmitting}>{t("cancel")}</button>
                                    </BControl>
                                    <ErrorDisplay error={formSubmissionError} inline={true} />
                                </BField>
                            </Form>
                        );
                    }}
                </Formik>
            );
        } else {
            return (
                <div>
                    <span>{value}</span>
                    <div className="button-box-inline">
                        <InlineButton onClick={this.switchToEditMode}>{t("edit")}</InlineButton>
                        <InlineButton onClick={this.setNextFreeContentType} loading={settingNextFreeContentType}>{t("select-next-free-id")}</InlineButton>
                    </div>
                    <ErrorDisplay error={formSubmissionError} inline={true} />
                </div >
            );
        }
    }
}

export default withTranslation()(ClientContentTypeEditor);