import * as React from "react";
import { AppStateStore } from "../../stores/appStateStore";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import Logout from "../public/Logout";
import { getDefaultRouteForUser } from "../../utils/getDefaultRouteForUser";
import { routes } from "../../../shared/config/routes";
import NavBarItemLink from "./NavBarItemLink";
import AdminNavBarElements from "../admin/AdminNavBarElements";
import { bind } from "bind-decorator";
import { faUser, faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithTranslation, withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { languageData, validLanguages } from "../../../shared/config/languageData";
import { changeOwnLanguage } from "../../communication/api";

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
}

interface IState {
    hamburgerMenuActive: boolean;
}

@inject("appStateStore")
@observer
class Navbar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            hamburgerMenuActive: false
        };
    }

    @bind
    private toggleHamburgerMenuActive() {
        this.setState({
            hamburgerMenuActive: !this.state.hamburgerMenuActive
        });
    }

    @bind
    private async switchLanguage(languageKey: string) {
        // tslint:disable-next-line: no-floating-promises
        i18n.changeLanguage(languageKey);

        if (this.props.appStateStore.user) {
            try {
                await changeOwnLanguage(languageKey);
            } catch (error) {
                console.error("Error while setting language", error);
            }
        }
    }

    public render() {
        const { t } = this.props;
        const { user } = this.props.appStateStore;
        const { hamburgerMenuActive } = this.state;

        let userState: JSX.Element = null;
        if (user) {
            userState = (
                <div className="navbar-item has-dropdown is-hoverable">
                    <a className="navbar-link"><FontAwesomeIcon icon={faUser} pull="left" /> {user.fullName}</a>
                    <div className="navbar-dropdown">
                        <NavBarItemLink to={routes.user}>{t("settings")}</NavBarItemLink>
                        <Logout className="navbar-item" />
                    </div>
                </div>
            );
        }

        const defaultRoute = getDefaultRouteForUser(user);

        const hamburgerMenuActiveClass = hamburgerMenuActive ? "is-active" : "";

        return (
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link className="navbar-item logo" to={defaultRoute}><h1>{t("site-title")}</h1></Link>
                    <a role="button" className={"navbar-burger burger " + hamburgerMenuActiveClass} aria-label="menu" aria-expanded="false"
                        data-target="menu" onClick={this.toggleHamburgerMenuActive}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="menu" className={"navbar-menu " + hamburgerMenuActiveClass}>
                    <div className="navbar-start">
                        {user && user.hasAdminRights && <AdminNavBarElements />}
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link"><FontAwesomeIcon icon={faGlobeAmericas} pull="left" /> {t("language")}</a>
                            <div className="navbar-dropdown">
                                {validLanguages.map(languageKey => (
                                    (languageKey === i18n.language)
                                        ? <strong key={languageKey} className="navbar-item">{languageData[languageKey].nativeName}</strong>
                                        : (
                                            <a key={languageKey} className="navbar-item" onClick={() => this.switchLanguage(languageKey)}>
                                                {languageData[languageKey].nativeName}
                                            </a>
                                        )
                                ))}
                            </div>
                        </div>
                        {userState}
                    </div>
                </div>
            </nav>
        );

    }
}

export default withTranslation()(Navbar);