import * as React from "react";
import { Formik, Form, Field } from "formik";
import { errorToString, requestPasswordReset } from "../../communication/api";
import { ErrorDisplay } from "../shared/ErrorDisplay";
import { AppStateStore } from "../../stores/appStateStore";
import { inject } from "mobx-react";
import { constants } from "../../../shared/config/constants";
import { routes } from "../../../shared/config/routes";
import { Link } from "react-router-dom";
import PageTitle from "../shared/PageTitle";
import { BLabel, BField, BControl } from "../shared/BulmaElements";
import Breadcrumbs from "../shared/Breadcrumbs";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
}

interface IState {
    success: boolean;
    formSubmissionError: string;
}

@inject("appStateStore")
class RequestPasswordReset extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            success: false,
            formSubmissionError: ""
        };
    }

    public componentDidMount() {
        this.props.appStateStore.closeLoginModal();
    }

    public render() {
        const { t } = this.props;
        const { success, formSubmissionError } = this.state;

        return (
            <div>
                <Breadcrumbs routeKeys={["root", "requestPasswordReset"]} />
                <PageTitle>{t("request-password-reset-title")}</PageTitle>
                <Formik
                    initialValues={{
                        username: "",
                        email: ""
                    }}
                    onSubmit={async (values, formikActions) => {
                        this.setState({
                            formSubmissionError: null,
                            success: false
                        });
                        try {
                            const { username, email } = values;
                            await requestPasswordReset(username, email);
                            this.setState({
                                success: true
                            });
                        } catch (err) {
                            if (err.response && err.response.status === 404) {
                                this.setState({ formSubmissionError: t("request-password-reset-no-account-found") });
                            } else {
                                this.setState({ formSubmissionError: errorToString(err) });
                            }
                        }
                        formikActions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, isSubmitting, values }) => (
                        <Form>
                            <BField>
                                {t("request-password-reset-info")}
                            </BField>
                            <BField>
                                <BLabel>{t("username")}</BLabel>
                                <BControl>
                                    <Field className="input" name="username" />
                                </BControl>
                            </BField>
                            <BField>
                                <BLabel>{t("email")}</BLabel>
                                <BControl>
                                    <Field className="input" name="email" type="email" />
                                </BControl>
                            </BField>
                            <BField>
                                <BControl>
                                    <button
                                        className={"button is-primary" + (isSubmitting ? " is-loading" : "")}
                                        type="submit" disabled={isSubmitting || (!values.username) || (!values.email)}>
                                        {t("request-password-reset-submit")}
                                    </button>
                                </BControl>
                            </BField>
                            <ErrorDisplay error={formSubmissionError} />
                            {success && (
                                <BField>
                                    {t("request-password-reset-success", { resetLinkAvailabilityMinutes: constants.resetLinkAvailabilityMinutes, email: values.email })}
                                </BField>
                            )}
                            <BField>
                                <Link to={routes.requestUsernames}>{t("forgot-your-username?")}</Link>
                            </BField>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withTranslation()(RequestPasswordReset);