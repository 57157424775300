import * as React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../shared/config/routes";
import Breadcrumbs from "../shared/Breadcrumbs";
import PageTitle from "../shared/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus, faEnvelope, faStamp, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import LogEntriesDashboard from "./logEntries/LogEntriesDashboard";

export default function AdminDashboard() {
    const { t } = useTranslation();

    return (
        <div className="admin-dashboard">
            <Breadcrumbs routeKeys={["admin"]} />
            <PageTitle>{t("dashboard-title")}</PageTitle>

            <div className="columns">
                <div className="column">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                <FontAwesomeIcon icon={faEnvelope} pull="left" /> {t("invitations")}
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="content">
                                {t("invitations-info")}
                            </div>
                        </div>
                        <footer className="card-footer">
                            <Link className="card-footer-item" to={routes.adminInvitationOverview}><FontAwesomeIcon icon={faList} pull="left" /> {t("overview")}</Link>
                            <Link className="card-footer-item" to={routes.adminInvitationCreate}><FontAwesomeIcon icon={faPlus} pull="left" /> {t("create-new")}</Link>
                        </footer>
                    </div>
                </div>

                <div className="column">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                <FontAwesomeIcon icon={faUsers} pull="left" /> {t("clients")}
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="content">
                                {t("clients-info")}
                            </div>
                        </div>
                        <footer className="card-footer">
                            <Link className="card-footer-item" to={routes.adminClientOverview}><FontAwesomeIcon icon={faList} pull="left" /> {t("overview")}</Link>
                        </footer>
                    </div>
                </div>

                <div className="column">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                <FontAwesomeIcon icon={faStamp} pull="left" /> {t("license-types")}
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="content">
                                {t("license-types-info")}
                            </div>
                        </div>
                        <footer className="card-footer">
                            <Link className="card-footer-item" to={routes.adminLicenseTypeOverview}><FontAwesomeIcon icon={faList} pull="left" /> {t("overview")}</Link>
                            <Link className="card-footer-item" to={routes.adminLicenseTypeCreate}><FontAwesomeIcon icon={faPlus} pull="left" /> {t("create-new")}</Link>
                        </footer>
                    </div>
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <div className="card log-entries">
                        <header className="card-header">
                            <p className="card-header-title">
                                <FontAwesomeIcon icon={faStamp} pull="left" /> {t("log-entries")}
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="content">
                                {t("log-entries-info")}
                                <LogEntriesDashboard />
                            </div>
                        </div>
                        <footer className="card-footer">
                            <Link className="card-footer-item" to={routes.adminLogEntriesOverview}><FontAwesomeIcon icon={faList} pull="left" /> {t("show-all")}</Link>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}
