import React from "react";

export function newLineToLineBreak(str: string) {
    if (!str)
        return str;

    const splitString = str.split("\n");
    if (splitString.length === 1)
        return str;

    const lastIndex = splitString.length - 1;
    return splitString.map((value, index) => (
        <span key={index}>{value}{(index !== lastIndex) ? <br /> : ""}</span>
    ));
}