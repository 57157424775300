import * as React from "react";
import "./PasswordInput.scss";
import zxcvbn from "zxcvbn";
import { FieldProps } from "formik";
import bind from "bind-decorator";
import { PASSWORD_MIN_LENGTH } from "../../../shared/definitions/validationSchemes/user";
import { WithTranslation, withTranslation } from "react-i18next";
import memoizeOne from "memoize-one";

interface IProps extends FieldProps, WithTranslation {
    disabled: boolean;
}

interface IState {
}

interface IStrengthState {
    tooShort: boolean;
    score: number;
}

// Deconstructed from https://github.com/mmw/react-password-strength
class PasswordInput extends React.Component<IProps, IState> {
    @bind
    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.field.onChange(e);
    }

    private getPasswordStrengthState = memoizeOne((password: string): IStrengthState => {
        const tooShort = password.length < PASSWORD_MIN_LENGTH;

        let score;
        if (tooShort) {
            score = 0;
        } else if (password.length > 40) {
            score = 4;
        } else {
            score = zxcvbn(password).score;
        }

        return {
            tooShort,
            score
        };
    });

    public render() {
        const { field, disabled, t } = this.props;
        const { tooShort, score } = this.getPasswordStrengthState(this.props.field.value);

        const scoreVerdicts = [
            "extremely-weak",
            "weak",
            "okay",
            "good",
            "strong"
        ];

        const verdict = tooShort
            ? ""
            : t("password-verdict-" + scoreVerdicts[score]);

        return (
            <span className={`passwordInput strength-${score}`}>
                <div className="strength-input-and-bar">
                    <input className="input" type="password" {...field} onChange={this.onChange} disabled={disabled} />
                    {this.props.field.value && <div className="strength-bar"></div>}
                </div>
                {verdict && <span className="help strength-verdict">{verdict}</span>}
            </span>
        );
    }
}

export default withTranslation()(PasswordInput);