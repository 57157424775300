import * as React from "react";
import { inject } from "mobx-react";
import { AppStateStore } from "../../stores/appStateStore";
import { login, resendAccountCreationBackupEmailConfirmation } from "../../communication/api";
import { bind } from "bind-decorator";
import { LoginError } from "../../../shared/definitions/loginError";
import { routes } from "../../../shared/config/routes";
import { ErrorDisplay } from "../shared/ErrorDisplay";
import { ResendAccountCreationBackupEmailConfirmationResult } from "../../../shared/definitions/apiResults/ResendAccountCreationBackupEmailConfirmationResult";
import { Link } from "react-router-dom";
import { BLabel, BControl, BField } from "../shared/BulmaElements";
import { WithTranslation, withTranslation } from "react-i18next";
import { AxiosResponse } from "axios";

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
    redirectAfterLogin: boolean;
}

interface IState {
    username: string;
    password: string;
    inProgress: boolean;
    loginError: LoginError;
    lockoutSeconds: number;
    showResendButton: boolean;
    resendError: ResendAccountCreationBackupEmailConfirmationResult;
    resendSuccess: boolean;
}

@inject("appStateStore")
class Login extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            inProgress: false,
            loginError: null,
            lockoutSeconds: null,
            showResendButton: false,
            resendError: null,
            resendSuccess: false
        };
    }

    @bind
    private setUsername(event) {
        this.setState({
            username: event.target.value,
            showResendButton: false
        });
    }

    @bind
    private setPassword(event) {
        this.setState({
            password: event.target.value,
            showResendButton: false
        });
    }

    @bind
    private async handleSubmit(event) {
        event.preventDefault();
        if (this.state.inProgress)
            return;

        this.setState({
            inProgress: true,
            showResendButton: false,
            loginError: null,
            lockoutSeconds: null,
            resendError: null,
            resendSuccess: false
        });

        const { username, password } = this.state;
        const { appStateStore, redirectAfterLogin } = this.props;

        try {
            const { user, error } = await login(username, password);
            if (user) {
                appStateStore.setUser(user);
                if (redirectAfterLogin && appStateStore.hasSavedUrlForAfterLogin) {
                    appStateStore.restoreUrlAfterLogin();
                }
            } else if (error) {
                this.setState({
                    loginError: error,
                    inProgress: false,
                    showResendButton: (error === LoginError.BackupEmailNotConfirmed)
                });
            }
        } catch (error) {
            if (error.response) {
                const { status, headers } = error.response as AxiosResponse;
                if (status === 429) {
                    const retryAfter = headers["retry-after"];
                    this.setState({
                        lockoutSeconds: retryAfter,
                        inProgress: false,
                        showResendButton: false
                    });
                }
            } else {
                throw error;
            }
        }
    }

    @bind
    private async resendBackupEmailConfirmation() {
        if (this.state.inProgress)
            return;

        this.setState({
            inProgress: true,
            loginError: null,
            resendError: null,
            resendSuccess: false
        });

        const { username, password } = this.state;
        const result = await resendAccountCreationBackupEmailConfirmation(username, password);
        if (result === ResendAccountCreationBackupEmailConfirmationResult.Success) {
            this.setState({
                showResendButton: false,
                resendError: null,
                resendSuccess: true,
                inProgress: false
            });
        } else {
            this.setState({
                resendError: result,
                inProgress: false
            });
        }
    }

    public render() {
        /*
        const [username, setUsername] = useState("");
        const [password, setPassword] = useState("");
        const [loginError, setLoginError] = useState("");
        const [inProgress, setInProgress] = useState(false);
        */

        const { t } = this.props;
        const { username, password, loginError, lockoutSeconds, inProgress, showResendButton, resendError, resendSuccess } = this.state;

        let errorText = "";
        if (lockoutSeconds) {
            errorText = t("login-too-many-tries", { count: Math.ceil(lockoutSeconds / 60) });
        } else if (loginError || resendError) {
            if ((loginError === LoginError.MissingCredentials) || (resendError === ResendAccountCreationBackupEmailConfirmationResult.MissingCredentials)) {
                errorText = t("login-error-missingCredentials");
            } else if ((loginError === LoginError.NotFoundOrWrongPassword) || (resendError === ResendAccountCreationBackupEmailConfirmationResult.NotFoundOrWrongPassword)) {
                errorText = t("login-error-notFoundOrWrongPassword");
            } else if ((loginError === LoginError.NotActive) || (resendError === ResendAccountCreationBackupEmailConfirmationResult.NotActive)) {
                errorText = t("login-error-notActive");
            } else if (loginError === LoginError.BackupEmailNotConfirmed) {
                errorText = t("login-error-backupEmailNotConfirmed");
            } else if (resendError === ResendAccountCreationBackupEmailConfirmationResult.AlreadyConfirmed) {
                errorText = t("login-error-alreadyConfirmed");
            } else {
                errorText = loginError || resendError;
            }
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <BField>
                    <BLabel>{t("username")}</BLabel>
                    <BControl>
                        <input className="input" type="text" name="username" value={username} onChange={this.setUsername} />
                    </BControl>
                </BField>
                <BField>
                    <BLabel>{t("password")}</BLabel>
                    <BControl>
                        <input className="input" type="password" name="password" value={password} onChange={this.setPassword} />
                    </BControl>
                </BField>
                <BField>
                    <BControl>
                        <button
                            className={"button is-primary" + (inProgress ? " is-loading" : "")}
                            type="submit"
                            disabled={inProgress}>
                            {t("login-submit")}
                        </button>
                    </BControl>
                </BField>
                <ErrorDisplay error={errorText} />
                <BField>
                    {showResendButton && <button className="button" onClick={this.resendBackupEmailConfirmation} disabled={inProgress}>{t("login-resend-confirmation-mail")}</button>}
                    {resendSuccess && <p>{t("login-confirmation-mail-resent")}</p>}
                    <div>
                        <Link to={routes.requestUsernames}>{t("forgot-your-username?")}</Link>
                    </div>
                    <div>
                        <Link to={routes.requestPasswordReset}>{t("forgot-your-password?")}</Link>
                    </div>
                </BField>
            </form>
        );
    }
}

export default withTranslation()(Login);