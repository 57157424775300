import * as React from "react";
import { routes } from "../../../shared/config/routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
    routeKeys: Array<keyof typeof routes | number>;
}

const localizationKeys = {
    admin: "dashboard-title",
    adminInvitationCreate: "create-new",
    adminInvitationOverview: "invitations",
    adminClientOverview: "clients",
    adminLicenseTypeCreate: "create-new",
    adminLicenseTypeOverview: "license-types",
    adminLogEntriesOverview: "log-entries",
    root: "login-title",
    requestPasswordReset: "request-password-reset-title",
    requestUsernames: "forgotten-usernames-title"
};

export default function Breadcrumbs(props: IProps) {
    const { t } = useTranslation();
    const { routeKeys } = props;

    return (
        <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
                {
                    routeKeys.map((key, index) => {
                        const label = t(localizationKeys[key]) || "#" + key;
                        if (index < (routeKeys.length - 1)) {
                            return (
                                <li key={key}>
                                    <Link to={routes[key] as string}>{label}</Link>
                                </li>
                            );
                        } else {
                            return <li key={key} className="is-active"><a>{label}</a></li>;
                        }
                    })
                }
            </ul>
        </nav>
    );
}
