import * as React from "react";
import { createCancelTokenSource, getLogEntryCountsByIP } from "../../../communication/api";
import bind from "bind-decorator";
import { CancelTokenSource } from "axios";
import { WithTranslation, withTranslation } from "react-i18next";
import PageTitle from "../../shared/PageTitle";
import LogEntriesInfo from "./LogEntriesInfo";
import { IAsyncLoadedObject, load } from "../../../utils/asyncLoader";
import { LogEntryCountsByIP } from "../../../../shared/definitions/apiResults/LogEntryCountsByIP";
import { constants } from "../../../../shared/config/constants";
import { Loading } from "../../shared/Loading";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import { Link } from "react-router-dom";
import { routes } from "../../../../shared/config/routes";

interface IProps extends WithTranslation {
}

interface IState {
    loadedData: IAsyncLoadedObject<LogEntryCountsByIP>;
}

class LogEntriesDashboard extends React.Component<IProps, IState> {
    public cancelTokenSource: CancelTokenSource;

    constructor(props: IProps) {
        super(props);

        this.cancelTokenSource = createCancelTokenSource();

        this.state = {
            loadedData: {
                error: "",
                loading: false,
                value: null
            }
        };
    }

    public async componentDidMount() {
        await this.load();
    }

    @bind
    public async load() {
        await load(this, "loadedData", getLogEntryCountsByIP);
    }

    public render() {
        const { t } = this.props;
        const { loadedData: { error, loading, value: logEntryCountsByIP } } = this.state;

        return (
            <div className="section">
                <PageTitle subtitle={3}>{t("log-entries-dashboard-title", { days: constants.logEntriesOverviewDashboardDays })}</PageTitle>
                {loading
                    ? <Loading />
                    : <table className="table">
                        <thead>
                            <tr>
                                <th className="ip">{t("ip")}</th>
                                <th className="severity severity-0">{t("severity-0")}</th>
                                <th className="severity severity-1">{t("severity-1")}</th>
                                <th className="severity severity-2">{t("severity-2")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logEntryCountsByIP && logEntryCountsByIP.map(logEntryCounts => (
                                <tr key={logEntryCounts.ip}>
                                    <td className="ip"><Link to={routes.adminLogEntriesOverviewWithIP(logEntryCounts.ip)}>{logEntryCounts.ip}</Link></td>
                                    <td className="severity severity-0">{logEntryCounts.logEntriesBySeverity[0]}</td>
                                    <td className="severity severity-1">{logEntryCounts.logEntriesBySeverity[1]}</td>
                                    <td className="severity severity-2">{logEntryCounts.logEntriesBySeverity[2]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                <ErrorDisplay error={error} />
                <LogEntriesInfo />
            </div>
        );
    }
}

export default withTranslation()(LogEntriesDashboard);