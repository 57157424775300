import * as React from "react";
import { AppStateStore } from "../../stores/appStateStore";
import { inject, observer } from "mobx-react";
import { Redirect, withRouter, Route, RouteComponentProps, Switch } from "react-router";
import { getDefaultRouteForUser } from "../../utils/getDefaultRouteForUser";
import AdminDashboard from "./AdminDashboard";
import ShowInvitation from "./invitations/ShowInvitation";
import PageNotFound from "../public/PageNotFound";
import InvitationsOverview from "./invitations/InvitationsOverview";
import CreateInvitation from "./invitations/CreateInvitation";
import { routes } from "../../../shared/config/routes";
import ShowClient from "./clients/ShowClient";
import ClientsOverview from "./clients/ClientsOverview";
import CreateLicenseType from "./licenseTypes/CreateLicenseType";
import ShowLicenseType from "./licenseTypes/ShowLicenseType";
import LicenseTypesOverview from "./licenseTypes/LicenseTypesOverview";
import LogEntriesOverview from "./logEntries/LogEntriesOverview";

interface IProps extends RouteComponentProps<any> {
    appStateStore?: AppStateStore;
}

interface IState {
}

@inject("appStateStore")
@observer
class AdminRoute extends React.Component<IProps, IState> {
    public render() {
        const { user } = this.props.appStateStore;
        if (!user || !user.hasAdminRights) {
            this.props.appStateStore.saveCurrentUrlBeforeRedirectingToLogin();
            return <Redirect to={getDefaultRouteForUser(user)} />;
        }

        return (
            <div>
                <Switch>
                    <Route exact path={routes.admin} component={AdminDashboard} />
                    <Route exact path={routes.adminInvitationCreate} component={CreateInvitation} />
                    <Route exact path={routes.adminInvitationView(":id")} component={ShowInvitation} />
                    <Route exact path={routes.adminInvitationOverview} component={InvitationsOverview} />
                    <Route exact path={routes.adminClientView(":id")} component={ShowClient} />
                    <Route exact path={routes.adminClientOverview} component={ClientsOverview} />
                    <Route exact path={routes.adminLicenseTypeCreate} component={CreateLicenseType} />
                    <Route exact path={routes.adminLicenseTypeView(":id")} component={ShowLicenseType} />
                    <Route exact path={routes.adminLicenseTypeOverview} component={LicenseTypesOverview} />
                    <Route exact path={routes.adminLogEntriesOverview} component={LogEntriesOverview} />
                    <Route exact path={routes.adminLogEntriesOverviewWithIP(":ip")} component={LogEntriesOverview} />
                    <Route component={PageNotFound} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(AdminRoute);