import * as React from "react";
import { errorToString, revokeClientLicense } from "../../../communication/api";
import { ErrorDisplay } from "../ErrorDisplay";
import bind from "bind-decorator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import { AxiosError } from "axios";
import { UserUpdated } from "./ChangeUserActiveState";
import { ModalDialog } from "../ModalDialog";
import { withTranslation, WithTranslation } from "react-i18next";

enum RevokingState {
    Waiting,
    Submitting,
    Success
}

interface IProps extends WithTranslation {
    userId: number;
    userUpdated: UserUpdated;
    reload: () => void;
    close: () => void;
}

interface IState {
    state: RevokingState;
    error: string;
    errorKey: string;
}

class RevokeLicenseModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            state: RevokingState.Waiting,
            error: null,
            errorKey: null
        };
    }

    @bind
    private async revokeLicense() {
        const { t, userId, userUpdated } = this.props;

        this.setState({
            state: RevokingState.Submitting,
            error: null,
            errorKey: null
        });

        try {
            const updatedUser = await revokeClientLicense(userId);
            this.setState({
                state: RevokingState.Success
            });
            userUpdated(updatedUser);
        } catch (err) {
            if (err.response !== null) {
                const axiosError = err as AxiosError;
                if (axiosError.response.status === 404) {
                    this.setState({
                        state: RevokingState.Waiting,
                        errorKey: "client-does-not-exist"
                    });
                    return;
                }
            }

            this.setState({
                state: RevokingState.Waiting,
                error: t("server-error", { error: errorToString(err) })
            });
        }
    }

    @bind
    private closeAndReload() {
        this.props.close();
        this.props.reload();
    }

    public render() {
        const { t, close } = this.props;
        const { state, error, errorKey } = this.state;

        const waiting = state === RevokingState.Waiting;
        const success = state === RevokingState.Success;

        if (success) {
            return (
                <ModalDialog
                    title={t("revoke-license-success-title")}
                    buttons={[
                        {
                            callback: this.props.close,
                            text: t("ok")
                        }
                    ]}
                    close={this.props.close}
                >
                    <div className="section">{t("revoke-license-success-text")}</div>
                </ModalDialog>
            );
        } else {
            const isLoading = !waiting;
            return (
                <ModalDialog
                    title={t("revoke-license?")}
                    buttons={[
                        {
                            callback: this.revokeLicense,
                            icon: faTimesCircle,
                            text: t("revoke-license-action"),
                            className: "is-danger",
                            isLoading
                        },
                        {
                            callback: close,
                            text: t("cancel")
                        }
                    ]}
                    close={close}
                    closeDisabled={isLoading}
                    buttonsDisabled={isLoading}
                    additionalFooterSection={(
                        <div>
                            <ErrorDisplay error={errorKey} translate />
                            <ErrorDisplay error={error} />
                            {(error || errorKey) && <button className="button" onClick={this.closeAndReload} disabled={isLoading}>
                                <FontAwesomeIcon icon={faSync} pull="left" /> {t("refresh")}
                            </button>}
                        </div>
                    )}
                >
                    <div className="section">
                        <p>{t("revoke-license-warning")}</p>
                    </div>
                </ModalDialog>
            );
        }
    }
}

export default withTranslation()(RevokeLicenseModal);