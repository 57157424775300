import * as React from "react";
import Login from "./Login";
import { AppStateStore } from "../../stores/appStateStore";
import { inject, observer } from "mobx-react";
import { getDefaultRouteForUser } from "../../utils/getDefaultRouteForUser";
import { Redirect } from "react-router";
import PageTitle from "../shared/PageTitle";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    appStateStore?: AppStateStore;
}

interface IState {
}

@inject("appStateStore")
@observer
class Home extends React.Component<IProps, IState> {
    public render() {
        const { t, appStateStore: { user } } = this.props;

        if (user) {
            return <Redirect to={getDefaultRouteForUser(user)} />;
        }

        return (
            <div>
                <PageTitle>{t("login-title")}</PageTitle>
                <Login redirectAfterLogin={true} />
            </div>
        );
    }
}

export default withTranslation()(Home);