import * as Yup from "yup";
import { ClientRole } from "../clientRole";
import { validLanguages } from "../../config/languageData";

export const createInvitationSchema = Yup.object().shape({
    email: Yup.string()
        .email("invalid-email")
        .required("required"),
    clientRole: Yup.string()
        .oneOf([
            ClientRole.ContentCreator,
            ClientRole.DeviceUser
        ], "invalid-option")
        .required("required"),
    language: Yup.string()
        .oneOf(validLanguages, "invalid-option")
        .required("required")
});
