import * as React from "react";
import { deleteInvitation, errorToString } from "../../../communication/api";
import { Link } from "react-router-dom";
import { routes } from "../../../../shared/config/routes";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import bind from "bind-decorator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import { DeleteInvitationResult } from "../../../../shared/definitions/apiResults/DeleteInvitationResult";
import { AxiosError } from "axios";
import { ModalDialog } from "../../shared/ModalDialog";
import { withTranslation, WithTranslation } from "react-i18next";

enum DeletionState {
    Waiting,
    InProgress,
    Success,
    PermanentlyFailed
}

interface IProps extends WithTranslation {
    id: number;
    reload: () => void;
    close: () => void;
}

interface IState {
    state: DeletionState;
    error: string;
    errorKey: string;
}

class DeleteInvitationModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            state: DeletionState.Waiting,
            error: null,
            errorKey: null
        };
    }

    @bind
    private async delete() {
        if (this.state.state !== DeletionState.Waiting)
            return;

        this.setState({
            state: DeletionState.InProgress,
            error: null,
            errorKey: null
        });

        try {
            const result = await deleteInvitation(this.props.id);
            switch (result) {
                case DeleteInvitationResult.Success:
                    this.setState({ state: DeletionState.Success });
                    break;

                case DeleteInvitationResult.AlreadyAccepted:
                    this.setState({
                        state: DeletionState.PermanentlyFailed,
                        errorKey: "delete-invitation-error-already-accepted"
                    });
            }
        } catch (err) {
            if (err.response !== null) {
                const axiosError = err as AxiosError;
                if (axiosError.response.status === 404) {
                    this.setState({
                        state: DeletionState.PermanentlyFailed,
                        errorKey: "delete-invitation-does-not-exist"
                    });
                    return;
                }
            }

            this.setState({
                state: DeletionState.Waiting,
                error: this.props.t("server-error", { error: errorToString(err) })
            });
        }
    }

    public render() {
        const { t, reload, close } = this.props;
        const { state, error, errorKey } = this.state;

        const isLoading = state === DeletionState.InProgress;

        return (
            <ModalDialog
                title={t("delete-invitation?")}
                buttons={[
                    {
                        callback: this.delete,
                        icon: faTimesCircle,
                        text: t("delete"),
                        className: "is-danger",
                        isLoading
                    },
                    {
                        callback: close,
                        text: t("cancel")
                    }
                ]}
                close={close}
                closeDisabled={isLoading}
                closeInvisible={state === DeletionState.Success}
                buttonsDisabled={state !== DeletionState.Waiting}
                additionalFooterSection={(
                    <div>
                        <ErrorDisplay error={errorKey} translate />
                        <ErrorDisplay error={error} />
                        {(error || errorKey) && (
                            <button className="button" onClick={reload} disabled={(state !== DeletionState.Waiting) && (state !== DeletionState.PermanentlyFailed)}>
                                <FontAwesomeIcon icon={faSync} pull="left" /> {t("refresh-invitation")}
                            </button>
                        )}
                        {(state === DeletionState.Success) && (
                            <div className="section">
                                {t("successfully-deleted")} <Link to={routes.adminInvitationOverview}>{t("back-to-overview")}</Link>
                            </div>
                        )}
                    </div>
                )}
            >
                <p className="section">{t("delete-invitation-warning")}</p>
                <p className="section">{t("delete-invitation-will-send-mail")}</p>
            </ModalDialog >
        );
    }
}

export default withTranslation()(DeleteInvitationModal);