import * as React from "react";
import { IUser } from "../../../../shared/definitions/models/IUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSlash, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import bind from "bind-decorator";
import { setClientActiveState, errorToString } from "../../../communication/api";
import { ErrorDisplay } from "../ErrorDisplay";
import InlineButton from "./InlineButton";
import { withTranslation, WithTranslation } from "react-i18next";

export type UserUpdated = (user: IUser) => void;

interface IProps extends WithTranslation {
    user: IUser;
    userUpdated: UserUpdated;
}

interface IState {
    submitting: boolean;
    error: string;
}

class ChangeUserActiveState extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            submitting: false,
            error: null
        };
    }

    private async setActiveState(value: boolean) {
        this.setState({
            submitting: true,
            error: null
        });

        try {
            const updatedClient = await setClientActiveState(this.props.user.id, value);
            this.props.userUpdated(updatedClient);
        } catch (err) {
            this.setState({
                error: errorToString(err)
            });
        }

        this.setState({
            submitting: false
        });
    }

    @bind
    private async deactivateUser() {
        await this.setActiveState(false);
    }

    @bind
    private async reactivateUser() {
        await this.setActiveState(true);
    }

    public render() {
        const { t } = this.props;
        const { active, clientData } = this.props.user;
        const { submitting, error } = this.state;

        const backupMailConfirmationNeeded = clientData && !clientData.backupEmail;

        return (
            <div>
                <span>{(active ? t("yes") : t("no")) + (backupMailConfirmationNeeded ? ` (${t("cannot-login-it-department-email-unconfirmed")} )` : "")}</span>
                <span className="button-box-inline">
                    {active
                        ? <InlineButton onClick={this.deactivateUser} loading={submitting}><FontAwesomeIcon icon={faUserSlash} pull="left" /> {t("temporarily-deactivate")}</InlineButton>
                        : <InlineButton onClick={this.reactivateUser} loading={submitting}><FontAwesomeIcon icon={faUserCheck} pull="left" /> {t("reactivate")}</InlineButton>
                    }
                </span>
                <ErrorDisplay error={error} inline={true} />
            </div>
        );
    }
}

export default withTranslation()(ChangeUserActiveState);