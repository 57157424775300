import * as React from "react";
import { AppStateStore } from "../../stores/appStateStore";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router";
import { getDefaultRouteForUser } from "../../utils/getDefaultRouteForUser";
import UserDetails from "../shared/UserDetails/UserDetails";
import bind from "bind-decorator";
import { updateOwnUserData, setOwnNewEmail, setOwnNewBackupEmail, resendOwnNewEmailConfirmation, resendOwnNewBackupEmailConfirmation, generateOwnRegistrationCode, releaseOwnDevice, updateOwnUserContentCreatorData } from "../../communication/api";
import PageTitle from "../shared/PageTitle";

interface IProps {
    appStateStore?: AppStateStore;
}

interface IState {
}

@inject("appStateStore")
@observer
export default class UserRoute extends React.Component<IProps, IState> {
    private async updateUserData(fieldName: string, value: string) {
        const updatedUser = await updateOwnUserData({
            [fieldName]: value
        });
        this.props.appStateStore.setUser(updatedUser);
    }

    @bind
    private async setFullName(value: string) {
        await this.updateUserData("fullName", value);
    }

    @bind
    private async setAddress(value: string) {
        await this.updateUserData("address", value);
    }

    @bind
    private async setPhoneNumber(value: string) {
        await this.updateUserData("phoneNumber", value);
    }

    @bind
    private async setNewEmail(value: string) {
        const updatedUser = await setOwnNewEmail(value);
        this.props.appStateStore.setUser(updatedUser);
    }

    @bind
    private async setNewBackupEmail(value: string) {
        const updatedUser = await setOwnNewBackupEmail(value);
        this.props.appStateStore.setUser(updatedUser);
    }

    @bind
    private async resendNewEmailConfirmation() {
        await resendOwnNewEmailConfirmation();
    }

    @bind
    private async resendNewBackupEmailConfirmation() {
        await resendOwnNewBackupEmailConfirmation();
    }

    private async updateContentCreatorData(fieldName: string, value: string) {
        const updatedUser = await updateOwnUserContentCreatorData({
            [fieldName]: value
        });
        this.props.appStateStore.setUser(updatedUser);
    }

    @bind
    private async setServerUrl(value: string) {
        await this.updateContentCreatorData("serverUrl", value);
    }

    @bind
    private async setServerUsername(value: string) {
        await this.updateContentCreatorData("serverUsername", value);
    }

    @bind
    private async setServerPassword(value: string) {
        await this.updateContentCreatorData("serverPassword", value);
    }

    @bind
    private async generateRegistrationCode() {
        const updatedUser = await generateOwnRegistrationCode();
        this.props.appStateStore.setUser(updatedUser);
    }

    @bind
    private async releaseDevice() {
        const updatedUser = await releaseOwnDevice();
        this.props.appStateStore.setUser(updatedUser);
    }

    public render() {
        const { user } = this.props.appStateStore;
        if (!user) {
            this.props.appStateStore.saveCurrentUrlBeforeRedirectingToLogin();
            return <Redirect to={getDefaultRouteForUser(user)} />;
        }

        return (
            <div>
                <PageTitle>User Dashboard</PageTitle>
                <UserDetails
                    user={user}
                    adminMode={false}
                    setFullName={this.setFullName}
                    setAddress={this.setAddress}
                    setPhoneNumber={this.setPhoneNumber}
                    setNewEmail={this.setNewEmail}
                    setNewBackupEmail={this.setNewBackupEmail}
                    resendNewEmailConfirmation={this.resendNewEmailConfirmation}
                    resendNewBackupEmailConfirmation={this.resendNewBackupEmailConfirmation}
                    setServerUrl={this.setServerUrl}
                    setServerUsername={this.setServerUsername}
                    setServerPassword={this.setServerPassword}
                    generateRegistrationCode={this.generateRegistrationCode}
                    releaseDevice={this.releaseDevice}
                />
            </div>
        );
    }
}
