import * as React from "react";
import { ILicenseType } from "../../../../shared/definitions/models/ILicenseType";
import { getLicenseTypes, createCancelTokenSource } from "../../../communication/api";
import { Link } from "react-router-dom";
import bind from "bind-decorator";
import { routes } from "../../../../shared/config/routes";
import { IAsyncLoadedObject, loadPaginatedReactTableData } from "../../../utils/asyncLoader";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import { IGetAllPaginatedResult } from "../../../../shared/definitions/apiResults/IGetAllPaginatedResult";
import ReactTable, { Filter, SortingRule } from "react-table";
import "react-table/react-table.css";
import { CancelTokenSource } from "axios";
import { createReactTableDropdownFilterFunction } from "../../shared/ReactTableDropdownFilter";
import { ClientRole } from "../../../../shared/definitions/clientRole";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageTitle from "../../shared/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faPlus, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import LinkButton from "../../shared/LinkButton";
import { WithTranslation, withTranslation } from "react-i18next";
import { getClientRoleDisplayText } from "../../../utils/getClientRoleDisplayText";
import { reactTableTranslationProps } from "../../../utils/reactTableTranslationProps";

interface IProps extends WithTranslation {
}

interface IState {
    loadedLicenseTypesPagination: IAsyncLoadedObject<IGetAllPaginatedResult<ILicenseType>>;
    page: number;
    pageSize: number;
    sorted: SortingRule[];
    filtered: Filter[];
}

const defaultPageSize = 10;

class LicenseTypesOverview extends React.Component<IProps, IState> {
    public cancelTokenSource: CancelTokenSource;
    public loadStateString: string;
    public previousAmountOfPages: number;

    constructor(props: IProps) {
        super(props);

        this.cancelTokenSource = createCancelTokenSource();

        this.state = {
            loadedLicenseTypesPagination: {
                error: "",
                loading: false,
                value: null
            },
            page: 0,
            pageSize: defaultPageSize,
            sorted: [{ id: "id", desc: false }],
            filtered: []
        };

        this.previousAmountOfPages = 0;
    }

    @bind
    public load() {
        loadPaginatedReactTableData(this, "loadedLicenseTypesPagination", getLicenseTypes);
    }

    // Called when the page index is changed by the user
    @bind
    private onPageChange(page: number) {
        this.setState({ page });
    }

    // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
    @bind
    private onPageSizeChange(pageSize: number, page: number) {
        this.setState({ pageSize, page });
    }

    // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
    @bind
    private onSortedChange(sorted: SortingRule[], column: any, additive: boolean) {
        this.setState({
            sorted,
            page: 0
        });
    }

    // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
    @bind
    private onFilteredChange(filtered: Filter[], column: any, value: any) {
        this.setState({
            filtered,
            page: 0
        });
    }

    @bind
    private resetFilters() {
        this.setState({
            filtered: [],
            page: 0
        }, () => this.load());
    }

    public render() {
        const { t } = this.props;
        const { page, pageSize, sorted, filtered } = this.state;
        const { loading, error, value: licenseTypesPagination } = this.state.loadedLicenseTypesPagination;
        let licenseTypes = [];
        let pages = this.previousAmountOfPages;
        if (licenseTypesPagination) {
            licenseTypes = licenseTypesPagination.rows;

            pages = Math.ceil(licenseTypesPagination.totalCount / pageSize);
            this.previousAmountOfPages = pages;
        }

        return (
            <div>
                <Breadcrumbs routeKeys={["admin", "adminLicenseTypeOverview"]} />
                <PageTitle>{t("license-types")}</PageTitle>
                <ErrorDisplay error={error} />
                <ReactTable
                    columns={[
                        {
                            Header: t("id"),
                            id: "id",
                            accessor: licenseType => "#" + licenseType.id
                        },
                        {
                            Header: t("name"),
                            accessor: "name"
                        },
                        {
                            Header: t("duration-months"),
                            accessor: "durationInMonths"
                        },
                        {
                            Header: t("role"),
                            id: "clientRole",
                            accessor: (licenseType: ILicenseType) => getClientRoleDisplayText(licenseType.clientRole, t),
                            Filter: createReactTableDropdownFilterFunction([
                                { value: ClientRole.DeviceUser, label: getClientRoleDisplayText(ClientRole.DeviceUser, t) },
                                { value: ClientRole.ContentCreator, label: getClientRoleDisplayText(ClientRole.ContentCreator, t) }
                            ])
                        },
                        {
                            Header: t("retired?"),
                            id: "retired",
                            accessor: (licenseType: ILicenseType) => licenseType.retired ? t("yes") : t("no"),
                            Filter: createReactTableDropdownFilterFunction([
                                { value: 1, label: t("yes") },
                                { value: 0, label: t("no") }
                            ])
                        },
                        {
                            Header: t("currently-in-use"),
                            accessor: "usedCount"
                        },
                        {
                            Header: "",
                            id: "open",
                            sortable: false,
                            accessor: licenseType => <Link to={routes.adminLicenseTypeView(licenseType.id)}><FontAwesomeIcon icon={faExternalLinkAlt} pull="left" /> {t("open")}</Link>,
                            Filter: () => <button className="button is-small" onClick={this.resetFilters}>{t("reset-filters")}</button>
                        }
                    ]}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={licenseTypes}
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    loadingText={t("loading")}
                    onFetchData={this.load} // Request new data when things change
                    filterable
                    defaultPageSize={defaultPageSize}
                    className="-striped -highlight"

                    page={page}
                    onPageChange={this.onPageChange}

                    pageSize={pageSize}
                    onPageSizeChange={this.onPageSizeChange}

                    sorted={sorted}
                    onSortedChange={this.onSortedChange}

                    filtered={filtered}
                    onFilteredChange={this.onFilteredChange}

                    {...reactTableTranslationProps(t)}
                />
                <button className={"button is-pulled-right" + (loading ? " is-loading" : "")} onClick={this.load} disabled={loading}><FontAwesomeIcon icon={faSync} pull="left" /> {t("refresh")}</button>
                <LinkButton className="button is-pulled-right" to={routes.adminLicenseTypeCreate}><FontAwesomeIcon icon={faPlus} pull="left" /> {t("create-new-license-type")}</LinkButton>
            </div>
        );
    }
}

export default withTranslation()(LicenseTypesOverview);