import * as React from "react";
import { routes } from "../../../../shared/config/routes";
import { Formik, Form, Field } from "formik";
import { ClientRole } from "../../../../shared/definitions/clientRole";
import { createInvitation, errorToString } from "../../../communication/api";
import { createInvitationSchema } from "../../../../shared/definitions/validationSchemes/invitation";
import { ErrorDisplayForm } from "../../shared/ErrorDisplayForm";
import { ErrorDisplay } from "../../shared/ErrorDisplay";
import LinkButton from "../../shared/LinkButton";
import { getClientRoleDisplayText } from "../../../utils/getClientRoleDisplayText";
import Breadcrumbs from "../../shared/Breadcrumbs";
import PageTitle from "../../shared/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { BField, BLabel, BControl, BSelect } from "../../shared/BulmaElements";
import { WithTranslation, withTranslation } from "react-i18next";
import { validLanguages } from "../../../../shared/config/languageData";

interface IProps extends WithTranslation {
}

interface IState {
    formSubmissionError: string;
    successId: number;
}

function createDefaultState() {
    return {
        formSubmissionError: "",
        successId: null
    };
}

class CreateInvitation extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = createDefaultState();
    }

    private reset(resetForm: () => void): void {
        resetForm();
        this.setState(createDefaultState());
    }

    public render() {
        const { t, i18n } = this.props;
        const { formSubmissionError, successId } = this.state;

        return (
            <div>
                <Breadcrumbs routeKeys={["admin", "adminInvitationOverview", "adminInvitationCreate"]} />
                <PageTitle>{t("create-invitation-title")}</PageTitle>
                <Formik
                    initialValues={{
                        email: "",
                        clientRole: "",
                        language: i18n.language
                    }}
                    validationSchema={createInvitationSchema}
                    onSubmit={async (values, formikActions) => {
                        this.setState({ formSubmissionError: null });
                        try {
                            const { email, clientRole, language } = values;
                            const invitation = await createInvitation(email, clientRole as ClientRole, language);
                            this.setState({
                                successId: invitation.id
                            });
                            //browserHistory.push(routes.adminInvitationView(invitation.id));
                        } catch (err) {
                            this.setState({ formSubmissionError: errorToString(err) });
                            formikActions.setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting, resetForm }) => (
                        <Form>
                            <BField>
                                <BLabel>{t("email")}</BLabel>
                                <BControl>
                                    <Field className="input" name="email" type="email" disabled={isSubmitting} />
                                </BControl>
                                <ErrorDisplayForm error={touched.email && errors.email} />
                            </BField>
                            <BField>
                                <BLabel>{t("role")}</BLabel>
                                <BControl>
                                    <BSelect>
                                        <Field name="clientRole" component="select" placeholder="" disabled={isSubmitting}>
                                            <option value="" disabled>{t("select-role")}</option>
                                            <option value={ClientRole.DeviceUser}>{getClientRoleDisplayText(ClientRole.DeviceUser, t)}</option>
                                            <option value={ClientRole.ContentCreator}>{getClientRoleDisplayText(ClientRole.ContentCreator, t)}</option>
                                        </Field>
                                    </BSelect>
                                </BControl>
                                <ErrorDisplayForm error={touched.clientRole && errors.clientRole} />
                            </BField>
                            <BField>
                                <BLabel>{t("language")}</BLabel>
                                <BControl>
                                    <BSelect>
                                        <Field name="language" component="select" placeholder="" disabled={isSubmitting}>
                                            <option value="" disabled>{t("select-language")}</option>
                                            {validLanguages.map(languageKey => (
                                                <option key={languageKey} value={languageKey}>{t("language-" + languageKey)}</option>
                                            ))}
                                        </Field>
                                    </BSelect>
                                </BControl>
                                <ErrorDisplayForm error={touched.language && errors.language} />
                            </BField>
                            <BField>
                                {t("create-invitation-will-send-mail")}
                            </BField>
                            <BField>
                                <BControl>
                                    <button
                                        className={"button is-primary" + ((isSubmitting && !successId) ? " is-loading" : "")}
                                        type="submit"
                                        disabled={isSubmitting}>
                                        {t("create-invitation-submit")}
                                    </button>
                                </BControl>
                            </BField>
                            <ErrorDisplay error={formSubmissionError} />
                            {successId && (
                                <div>
                                    <BField>
                                        {t("create-invitation-success")}
                                    </BField>
                                    <BField className="is-grouped">
                                        <BControl>
                                            <LinkButton className="button" to={routes.adminInvitationView(successId)}><FontAwesomeIcon icon={faExternalLinkAlt} pull="left" /> {t("open-invitation")}</LinkButton>
                                        </BControl>
                                        <BControl>
                                            <button className="button" onClick={() => this.reset(resetForm)}><FontAwesomeIcon icon={faPlus} pull="left" /> {t("create-another-invitation")}</button>
                                        </BControl>
                                    </BField>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withTranslation()(CreateInvitation);