import * as React from "react";

interface IProps {
    children: any;
}

export function ModalCard(props: IProps) {
    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                {props.children}
            </div>
        </div >
    );
}