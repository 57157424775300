import { IUser } from "../../shared/definitions/models/IUser";
import { routes } from "../../shared/config/routes";

export function getDefaultRouteForUser(user: IUser): string {
    if (!user)
        return routes.root;

    if (user.hasAdminRights)
        return routes.admin;

    return routes.user;
}