import * as React from "react";
import NavBarItemLink from "../shared/NavBarItemLink";
import { routes } from "../../../shared/config/routes";
import { useTranslation } from "react-i18next";

export default function AdminNavBarElements() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="navbar-item has-dropdown is-hoverable">
                <NavBarItemLink className="navbar-link" to={routes.adminInvitationOverview}>{t("invitations")}</NavBarItemLink>
                <div className="navbar-dropdown">
                    <NavBarItemLink to={routes.adminInvitationOverview}>{t("overview")}</NavBarItemLink>
                    <NavBarItemLink to={routes.adminInvitationCreate}>{t("create-new")}</NavBarItemLink>
                </div>
            </div>
            <NavBarItemLink to={routes.adminClientOverview}>{t("clients")}</NavBarItemLink>
            <div className="navbar-item has-dropdown is-hoverable">
                <NavBarItemLink className="navbar-link" to={routes.adminLicenseTypeOverview}>{t("license-types")}</NavBarItemLink>
                <div className="navbar-dropdown">
                    <NavBarItemLink to={routes.adminLicenseTypeOverview}>{t("overview")}</NavBarItemLink>
                    <NavBarItemLink to={routes.adminLicenseTypeCreate}>{t("create-new")}</NavBarItemLink>
                </div>
            </div>
            <NavBarItemLink to={routes.adminLogEntriesOverview}>{t("log-entries")}</NavBarItemLink>
        </React.Fragment>
    );
}
