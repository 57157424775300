import * as React from "react";
import "normalize.css";
import "./../assets/scss/App.scss";
import { hot } from "react-hot-loader";
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import Home from "./public/Home";
import UserRoute from "./user/UserRoute";
import AdminRoute from "./admin/AdminRoute";
import { observer, inject } from "mobx-react";
import { AppStateStore } from "../stores/appStateStore";
import { getLoggedInUserData, errorToString } from "../communication/api";
import PageNotFound from "./public/PageNotFound";
import { routes } from "../../shared/config/routes";
import SignUp from "./public/SignUp";
import { ErrorDisplay } from "./shared/ErrorDisplay";
import ConfirmEmail from "./public/ConfirmEmail";
import ConfirmBackupEmail from "./public/ConfirmBackupEmail";
import LoginModal from "./public/LoginModal";
import RequestUsernames from "./public/RequestForgottenUsernames";
import RequestPasswordReset from "./public/RequestPasswordReset";
import ResetPassword from "./public/ResetPassword";
import Navbar from "./shared/Navbar";

interface IProps extends RouteComponentProps<any> {
    appStateStore?: AppStateStore;
}

interface IState {
    loading: boolean;
    error: string;
}

@inject("appStateStore")
@observer
class App extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            error: null
        };
    }

    public async componentDidMount() {
        try {
            const result = await getLoggedInUserData();
            this.props.appStateStore.setUser(result.user);
        } catch (err) {
            this.setState({ error: errorToString(err) });
        }
        this.setState({ loading: false });
    }

    public render() {
        if (this.state.loading)
            return null; //<Loading />;

        if (this.state.error)
            return <ErrorDisplay error={this.state.error} />;

        const { showLoginModal } = this.props.appStateStore;

        return (
            <React.Suspense fallback="">
                <Navbar />
                <div className="app">
                    <Switch>
                        <Route exact path={routes.root} component={Home} />
                        <Route exact path={routes.requestUsernames} component={RequestUsernames} />
                        <Route exact path={routes.requestPasswordReset} component={RequestPasswordReset} />
                        <Route exact path={routes.resetPassword(":code")} component={ResetPassword} />
                        <Route exact path={routes.signUpWithInvitationCode(":invitationCode")} component={SignUp} />
                        <Route exact path={routes.confirmNewEmail(":code")} component={ConfirmEmail} />
                        <Route exact path={routes.confirmNewBackupEmail(":code")} component={ConfirmBackupEmail} />
                        <Route path={routes.admin} component={AdminRoute} />
                        <Route exact path={routes.user} component={UserRoute} />
                        <Route component={PageNotFound} />
                    </Switch>
                </div>
                {showLoginModal && <LoginModal />}
            </React.Suspense>
        );

    }
}

export default hot(module)(withRouter(App));