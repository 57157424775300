import * as React from "react";
import { Email } from "../Email";
import { SetFieldValue } from "./UserDetailsEditableField";
import bind from "bind-decorator";
import { errorToString } from "../../../communication/api";
import { ErrorDisplay } from "../ErrorDisplay";
import { Formik, Form, Field } from "formik";
import { ErrorDisplayForm } from "../ErrorDisplayForm";
import { emailSchema } from "../../../../shared/definitions/validationSchemes/user";
import { BControl, BField } from "../BulmaElements";
import InlineButton from "./InlineButton";
import { WithTranslation, withTranslation, Trans } from "react-i18next";

export type ExecuteAsyncAction = () => Promise<void>;

enum SubmissionState {
    Nothing,
    CancellingSwitch,
    ResendingEmail
}

interface IProps extends WithTranslation {
    email: string;
    newEmail: string;
    setNewEmail: SetFieldValue;
    resendNewEmailConfirmation: ExecuteAsyncAction;
}

interface IState {
    formSubmissionError: string;
    editing: boolean;
    submissionState: SubmissionState;
    success: boolean;
}

class UserDetailsMailFieldBase extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            formSubmissionError: "",
            editing: false,
            submissionState: SubmissionState.Nothing,
            success: false
        };
    }

    @bind
    private switchToEditMode() {
        this.setState({
            editing: true,
            success: false
        });
    }

    @bind
    private cancelEditing() {
        this.setState({
            editing: false
        });
    }

    @bind
    private async setNewEmail(newEmail: string) {
        if (this.props.email === newEmail) {
            newEmail = "";
        }

        this.setState({
            formSubmissionError: null,
            success: false
        });

        if (!this.props.newEmail && !newEmail) {
            this.setState({
                editing: false
            });
            return;
        }

        try {
            await this.props.setNewEmail(newEmail);
            this.setState({
                editing: false
            });
        } catch (err) {
            this.setState({
                formSubmissionError: errorToString(err)
            });
        }

        this.setState({
            submissionState: SubmissionState.Nothing
        });
    }

    @bind
    private async cancelSwitch() {
        this.setState({
            submissionState: SubmissionState.CancellingSwitch
        });

        await this.setNewEmail("");
    }

    @bind
    private async resendConfirmation() {
        this.setState({
            submissionState: SubmissionState.ResendingEmail,
            formSubmissionError: null,
            success: false
        });

        try {
            await this.props.resendNewEmailConfirmation();
            this.setState({
                success: true
            });
        } catch (err) {
            this.setState({
                formSubmissionError: errorToString(err)
            });
        }

        this.setState({
            submissionState: SubmissionState.Nothing
        });
    }

    public render() {
        const { t } = this.props;
        const { editing, formSubmissionError, submissionState, success } = this.state;
        const { email, newEmail } = this.props;

        let valueDisplay: JSX.Element;
        if (!email) {
            valueDisplay = <span><Email email={newEmail} /> ({t("unconfirmed")})</span>;
        } else {
            valueDisplay = <span><Email email={email} /> {(newEmail ? <span>(<Trans>email-being-changed<Email email={newEmail} /></Trans>)</span> : "")}</span>;
        }

        let editArea: JSX.Element;
        if (editing) {
            editArea = (
                <Formik
                    initialValues={{
                        newEmail: newEmail || ""
                    }}
                    onSubmit={async (values, formikActions) => {
                        await this.setNewEmail(values.newEmail);
                        formikActions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, isSubmitting, values }) => (
                        <Form>
                            <BField className="is-grouped">
                                <BControl>
                                    <Field
                                        className="input is-small"
                                        name="newEmail"
                                        type="email"
                                        validate={async (fieldValue: string) => {
                                            try {
                                                await emailSchema.validate(fieldValue);
                                                return null;
                                            } catch (err) {
                                                return err.message;
                                            }
                                        }}
                                    />
                                    <ErrorDisplayForm error={touched.newEmail && errors.newEmail} />
                                </BControl>
                                <BControl>
                                    <button
                                        className={"button is-small is-primary" + (isSubmitting ? " is-loading" : "")}
                                        type="submit"
                                        disabled={isSubmitting || (values.newEmail === newEmail) || (!values.newEmail) || (touched.newEmail && !!errors.newEmail)}>
                                        {t("user-details-field-update")}
                                    </button>
                                    <button className="button is-small" type="button" onClick={this.cancelEditing} disabled={isSubmitting}>{t("cancel")}</button>
                                </BControl>
                            </BField>
                        </Form>
                    )}
                </Formik>
            );
        } else {
            const busy = submissionState !== SubmissionState.Nothing;
            editArea = newEmail
                ? (
                    <div className="button-box-inline">
                        <InlineButton onClick={this.resendConfirmation} disabled={busy} loading={submissionState === SubmissionState.ResendingEmail}>{t("resend-confirmation-email")}</InlineButton>
                        <InlineButton onClick={this.cancelSwitch} disabled={busy} loading={submissionState === SubmissionState.CancellingSwitch}>{t("cancel-email-switch")}</InlineButton>
                    </div>
                )
                : (
                    <div className="button-box-inline">
                        <InlineButton onClick={this.switchToEditMode}>{t("switch")}</InlineButton>
                    </div>
                );
        }

        return (
            <div>
                {valueDisplay}
                {editArea}
                {success && <span>{t("resend-confirmation-email-success")}</span>}
                <ErrorDisplay error={formSubmissionError} inline={true} />
            </div>
        );
    }
}

const UserDetailsMailField = withTranslation()(UserDetailsMailFieldBase);

export default UserDetailsMailField;

export function makeDetailDisplayUserDetailsMailField(key: string, email: string, newEmail: string, setNewEmail: SetFieldValue, resendNewEmailConfirmation: ExecuteAsyncAction) {
    return {
        key,
        value: <UserDetailsMailField email={email} newEmail={newEmail} setNewEmail={setNewEmail} resendNewEmailConfirmation={resendNewEmailConfirmation} />
    };
}
