import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import browserHistory from "../../utils/browserHistory";

const LinkButton = props => {
    const {
        history,
        location,
        match,
        staticContext,
        to,
        onClick,
        // ⬆ filtering out props that `button` doesn’t know what to do with
        // (and onClick which we want to treat specifically).
        ...rest
    } = props;
    return (
        <button
            {...rest} // `children` is just another prop!
            onClick={event => {
                if (onClick)
                    onClick(event);

                if (history) {
                    history.push(to);
                } else {
                    browserHistory.push(to);
                }
            }}
        />
    );
};

LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default withRouter(LinkButton);