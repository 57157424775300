import * as React from "react";
import { match } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { getInvitationDataForSignUp, signUp, errorToString } from "../../communication/api";
import { IAsyncLoadedObject, load } from "../../utils/asyncLoader";
import { IInvitationDataForSignUpResult } from "../../../shared/definitions/apiResults/IInvitationDataForSignUpResult";
import { signupSchema } from "../../../shared/definitions/validationSchemes/user";
import { Loading } from "../shared/Loading";
import { ErrorDisplay } from "../shared/ErrorDisplay";
import { ErrorDisplayForm } from "../shared/ErrorDisplayForm";
import { InvitationDataForSignUpStatus } from "../../../shared/definitions/apiResults/InvitationDataForSignUpStatus";
import { SignUpResult } from "../../../shared/definitions/apiResults/ISignUpResult";
import PasswordInput from "../shared/PasswordInput";
import { newLineToLineBreak } from "../../utils/newLineToLineBreak";
import { getClientRoleDisplayText } from "../../utils/getClientRoleDisplayText";
import PageTitle from "../shared/PageTitle";
import { BField, BLabel, BControl } from "../shared/BulmaElements";
import { WithTranslation, withTranslation } from "react-i18next";
import i18next from "i18next";

interface IMatchParameters {
    invitationCode: string;
}

interface IProps extends WithTranslation {
    match: match<IMatchParameters>;
}

interface IState {
    loadedInvitationDataForSignUp: IAsyncLoadedObject<IInvitationDataForSignUpResult>;
    formSubmissionError: string;
    signupSuccessful: boolean;
}

class SignUp extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loadedInvitationDataForSignUp: {
                error: "",
                loading: false,
                value: null
            },
            formSubmissionError: null,
            signupSuccessful: false
        };
    }

    public async componentDidMount() {
        const result = await load(this, "loadedInvitationDataForSignUp", () => getInvitationDataForSignUp(this.props.match.params.invitationCode));
        if (result.value && result.value.invitationData && result.value.invitationData.language) {
            await i18next.changeLanguage(result.value.invitationData.language);
        }
    }

    public render() {
        const { t } = this.props;
        const { invitationCode } = this.props.match.params;
        const { formSubmissionError, loadedInvitationDataForSignUp, signupSuccessful } = this.state;

        if (loadedInvitationDataForSignUp.error)
            return <ErrorDisplay error={loadedInvitationDataForSignUp.error} />;

        if (!loadedInvitationDataForSignUp.value)
            return <Loading />;

        const { status: invitationStatus, invitationData } = loadedInvitationDataForSignUp.value;

        if (invitationStatus === InvitationDataForSignUpStatus.NotFound)
            return <ErrorDisplay error="invitation-not-found" translate />;

        if (invitationStatus === InvitationDataForSignUpStatus.AlreadyUsed)
            return <ErrorDisplay error="invitation-already-used" translate />;

        const { clientRole, email } = invitationData;

        const clientRoleText = getClientRoleDisplayText(clientRole, t);

        return (
            <div>
                <PageTitle>{t("sign-up-title")}</PageTitle>
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                        passwordConfirmation: "",
                        fullName: "",
                        address: "",
                        phoneNumber: "",
                        backupEmail: ""
                    }}
                    validationSchema={signupSchema}
                    onSubmit={async (values, formikActions) => {
                        this.setState({ formSubmissionError: null });
                        try {
                            const { username, password, fullName, address, phoneNumber, backupEmail } = values;
                            const { result } = await signUp(invitationCode, username, password, fullName, address, phoneNumber, backupEmail, i18next.language);
                            switch (result) {
                                case SignUpResult.Success:
                                    this.setState({ signupSuccessful: true });
                                    break;

                                case SignUpResult.InvitationAlreadyUsed:
                                    this.setState({
                                        formSubmissionError: t("invitation-submission-error-already-used")
                                    });
                                    break;

                                case SignUpResult.InvitationNotFound:
                                    this.setState({
                                        formSubmissionError: t("invitation-submission-error-rescinded")
                                    });
                                    break;

                                case SignUpResult.UsernameAlreadyInUse:
                                    formikActions.setFieldError("username", "username-taken");
                                    break;
                            }
                        } catch (err) {
                            this.setState({ formSubmissionError: errorToString(err) });
                        }

                        formikActions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, isSubmitting, values }) => {
                        if (!signupSuccessful) {
                            return (
                                <Form>
                                    <BField>
                                        <BLabel>{t("invitation-code")}</BLabel>
                                        <BControl>
                                            <Field className="input" value={invitationCode} disabled />
                                        </BControl>
                                    </BField>
                                    <BField>
                                        <BLabel>{t("email")}</BLabel>
                                        <BControl>
                                            <Field className="input" value={email} disabled />
                                        </BControl>
                                    </BField>
                                    <BField>
                                        <BLabel>{t("role")}</BLabel>
                                        <BControl>
                                            <Field className="input" value={clientRoleText} disabled />
                                        </BControl>
                                    </BField>
                                    <BField>
                                        <BLabel>{t("username")}</BLabel>
                                        <BControl>
                                            <Field className="input" name="username" disabled={isSubmitting} />
                                        </BControl>
                                        <ErrorDisplayForm error={touched.username && errors.username} />
                                    </BField>
                                    <BField>
                                        <BLabel>{t("password")}</BLabel>
                                        <BControl>
                                            <Field name="password" component={PasswordInput} disabled={isSubmitting} />
                                        </BControl>
                                        <ErrorDisplayForm error={touched.password && errors.password} />
                                    </BField>
                                    <BField>
                                        <BLabel>{t("confirm-password")}</BLabel>
                                        <BControl>
                                            <Field className="input" name="passwordConfirmation" type="password" disabled={isSubmitting} />
                                        </BControl>
                                        <ErrorDisplayForm error={touched.passwordConfirmation && errors.passwordConfirmation} />
                                    </BField>
                                    <BField>
                                        <BLabel>{t("full-name")}</BLabel>
                                        <BControl>
                                            <Field className="input" name="fullName" disabled={isSubmitting} />
                                        </BControl>
                                        <ErrorDisplayForm error={touched.fullName && errors.fullName} />
                                    </BField>
                                    <BField>
                                        <BLabel>{t("address")}</BLabel>
                                        <BControl>
                                            <Field className="textarea" name="address" component="textarea" disabled={isSubmitting} />
                                        </BControl>
                                        <ErrorDisplayForm error={touched.address && errors.address} />
                                    </BField>
                                    <BField>
                                        <BLabel>{t("phone-number")}</BLabel>
                                        <BControl>
                                            <Field className="input" name="phoneNumber" disabled={isSubmitting} />
                                        </BControl>
                                        <ErrorDisplayForm error={touched.phoneNumber && errors.phoneNumber} />
                                    </BField>
                                    <BField>
                                        <BLabel>{t("it-department-email")}</BLabel>
                                        <BControl>
                                            <Field className="input" name="backupEmail" disabled={isSubmitting} />
                                        </BControl>
                                        <ErrorDisplayForm error={touched.backupEmail && errors.backupEmail} />
                                        {t("it-department-email-info")}
                                    </BField>
                                    <BField>
                                        <BControl>
                                            <button className={"button is-primary" + (isSubmitting ? " is-loading" : "")} type="submit" disabled={isSubmitting}>{t("sign-up-submit")}</button>
                                        </BControl>
                                    </BField>
                                    <ErrorDisplay error={formSubmissionError} />
                                </Form>
                            );
                        } else {
                            return (
                                <div>
                                    <table className="vertical">
                                        <tbody>
                                            <tr><th>{t("invitation-code")}:</th><td>{invitationCode}</td></tr>
                                            <tr><th>{t("email")}:</th><td>{email}</td></tr>
                                            <tr><th>{t("role")}:</th><td>{clientRoleText}</td></tr>
                                            <tr><th>{t("username")}:</th><td>{values.username}</td></tr>
                                            <tr><th>{t("full-name")}:</th><td>{values.fullName}</td></tr>
                                            <tr><th>{t("address")}:</th><td>{newLineToLineBreak(values.address)}</td></tr>
                                            <tr><th>{t("phone-number")}:</th><td>{values.phoneNumber}</td></tr>
                                            <tr><th>{t("it-department-email")}:</th><td>{values.backupEmail}</td></tr>
                                        </tbody>
                                    </table>
                                    <p className="section">
                                        {t("sign-up-successful", { email: invitationData.email, backupEmail: values.backupEmail })}
                                    </p>
                                </div>
                            );
                        }
                    }}
                </Formik>
            </div>
        );
    }
}

export default withTranslation()(SignUp);