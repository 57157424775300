import * as React from "react";
import { Formik, Form, Field } from "formik";
import { errorToString, resetPassword } from "../../communication/api";
import { ErrorDisplay } from "../shared/ErrorDisplay";
import { inject } from "mobx-react";
import { routes } from "../../../shared/config/routes";
import { Link, match } from "react-router-dom";
import { ErrorDisplayForm } from "../shared/ErrorDisplayForm";
import PasswordInput from "../shared/PasswordInput";
import { passwordResetSchema } from "../../../shared/definitions/validationSchemes/user";
import PageTitle from "../shared/PageTitle";
import { BField, BLabel, BControl } from "../shared/BulmaElements";
import { WithTranslation, withTranslation, Trans } from "react-i18next";

interface IMatchParameters {
    code: string;
}

interface IProps extends WithTranslation {
    match: match<IMatchParameters>;
}

interface IState {
    success: boolean;
    formSubmissionError: string | JSX.Element;
}

@inject("appStateStore")
class ResetPassword extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            success: false,
            formSubmissionError: ""
        };
    }

    public render() {
        const { t } = this.props;
        const { success, formSubmissionError } = this.state;

        return (
            <div>
                <PageTitle>{t("reset-password-title")}</PageTitle>
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                        passwordConfirmation: ""
                    }}
                    validationSchema={passwordResetSchema}
                    onSubmit={async (values, formikActions) => {
                        this.setState({
                            formSubmissionError: null,
                            success: false
                        });
                        try {
                            const { code } = this.props.match.params;
                            const { username, password } = values;
                            await resetPassword(username, code, password);
                            this.setState({
                                success: true
                            });
                        } catch (err) {
                            if (err.response && err.response.status === 404) {
                                this.setState({
                                    formSubmissionError: (
                                        <Trans>password-reset-expired<Link to={routes.requestPasswordReset}>request-new-password-reset</Link></Trans>
                                    )
                                });
                            } else {
                                this.setState({ formSubmissionError: errorToString(err) });
                            }
                            formikActions.setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <BField>
                                {t("reset-password-enter-info")}
                            </BField>
                            <BField>
                                <BLabel>{t("username")}</BLabel>
                                <BControl>
                                    <Field className="input" name="username" disabled={isSubmitting} />
                                </BControl>
                                <ErrorDisplayForm error={touched.username && errors.username} />
                            </BField>
                            <BField>
                                <BLabel>{t("new-password")}</BLabel>
                                <BControl>
                                    <Field name="password" component={PasswordInput} disabled={isSubmitting} />
                                </BControl>
                                <ErrorDisplayForm error={touched.password && errors.password} />
                            </BField>
                            <BField>
                                <BLabel>{t("confirm-new-password")}</BLabel>
                                <BControl>
                                    <Field className="input" name="passwordConfirmation" type="password" disabled={isSubmitting} />
                                </BControl>
                                <ErrorDisplayForm error={touched.passwordConfirmation && errors.passwordConfirmation} />
                            </BField>

                            <BField>
                                <BControl>
                                    <button
                                        className={"button is-primary" + ((isSubmitting && !success) ? " is-loading" : "")}
                                        type="submit"
                                        disabled={isSubmitting}>
                                        {t("reset-password-submit")}
                                    </button>
                                </BControl>
                            </BField>

                            <ErrorDisplay error={formSubmissionError} />
                            {success && (
                                <BField>
                                    <Trans>password-reset-success<Link to={routes.root}>log-in</Link></Trans>
                                </BField>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withTranslation()(ResetPassword);